
import React from "react";
// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Table,
    Row,
    Col
} from "reactstrap";
import Loader from "components/Loader";
import SchemaFooter from "components/SchemaFooter";
import SchemaHeader from "components/SchemaHeader";
import { Base64 } from 'js-base64';




class Befaring extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            loading: true,
            schemaDataObject: {},
        };
    }

    componentDidMount() {

        //Load schema data
        fetch("https://rokeagruppenapi.azurewebsites.net/api/Customer/CustomerSchemaContent?jobId=" + this.props.jobId + "&schemaId=9", {
            method: "GET",
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + Base64.encode(sessionStorage.getItem("id") + ":" + sessionStorage.getItem("value")).replace(/['"]+/g, ''),
                'CompanyID': sessionStorage.getItem("companyID")
            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        loading: false,
                        schemaDataObject: result,
                    });
                },
                (error) => {
                    this.setState({
                        loading: false,
                        error: true
                    });
                }
            )
    }


    render() {

        const { error, loading, schemaDataObject } = this.state;
        if (loading) {
            return <Loader />
        }

        if (error) {
            return <h2>AUCH!!</h2>
        }

        return (
            <>
                <div className="content">

                    <Row>
                        <Col md="12">
                            <Card>
                                <SchemaHeader></SchemaHeader>
                                <div id="printDiv">
                                    {/* ---------------- BEFARING ----------------  */}
                                    <CardHeader>
                                        <CardTitle tag="h4">Befaringsskjema</CardTitle>
                                    </CardHeader>

                                    <CardBody>
                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td><b>Beskrivelse:</b></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ borderTop: 1 + "px" }} >{schemaDataObject.Description}</td>
                                                </tr>
                                            </tbody>
                                        </Table>


                                        {/* ---------------- MONTØR ----------------  */}
                                        <hr></hr>
                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td style={{ width: "30%", borderTop: 1 + "px", color: "black" }} ><b>Medgått tid - ikke fakturerbar:</b></td>
                                                    <td style={{ borderTop: 1 + "px" }} >{schemaDataObject.hourDriving}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: "30%", borderTop: 0 + "px" }} ><b>Medgått tid - fakturerbar:</b></td>
                                                    <td style={{ borderTop: 0 + "px" }} > {schemaDataObject.hourSchema} </td>
                                                </tr>
                                            </tbody>
                                        </Table>

                                        <br></br><br></br>

                                        {/* ---------------- FOOTER ----------------  */}

                                        <SchemaFooter Date={schemaDataObject.Date} Montor={schemaDataObject.Montor} FooterText={this.props.footerText} CompanyURL={this.props.companyUrl} />

                                    </CardBody>
                                </div>
                            </Card>
                        </Col>
                    </Row>

                </div>
            </>
        )

    }
}

export default Befaring;
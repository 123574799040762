import React from "react";
import classnames from "classnames";
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";
// reactstrap components
import { FormGroup, Row, Col } from "reactstrap";
import Loader from "components/Loader";



const userRights = [{value: "Employee", label: "Default User Rights"}, {value: "Admin", label: "Administrator User Rights"}]

class Wizard extends React.Component {
  constructor(props) {
    super(props);
   
    
    this.state = {
      loading: false,
    };
  }


  componentDidMount() {

    //this.setState({ loading: true });

   
  }


  isValidated = () => {
    if (
      this.state.companyState === "has-success" &&
      this.state.departmentState === "has-success"
    ) {
      return true;
    } else {
      if (this.state.companyState !== "has-success") {
        this.setState({ companyState: "has-danger" });
      }
      if (this.state.departmentState !== "has-success") {
        this.setState({ departmentState: "has-danger" });
      }
      return false;
    }
  };

  render() {

    const { companies, departments, loading, company, department, userRight } = this.state;

    if (loading) {
      return <Loader />
    }
   
    return (
      <>
        <h5 className="info-text">Assign to company and department and set access level</h5>

        <Row className="justify-content-center">
          <Col lg="6">
          <FormGroup
              className={classnames(this.state.companyState, {
                "input-group-focus": this.state.companyFocus
              })}
            >
              <Select
                name="company"
                className="react-select"
                placeholder={company}
                classNamePrefix="react-select"
                value={company}                
                onChange={this.onSelectedCompanyChange}
                options={ companies }
              />
                {this.state.companyState === "has-danger" ? (
                  <label className="error">Please select a company</label>
                ) : null}

            </FormGroup>
          </Col>
        </Row>
        
        <Row className="justify-content-center">
        <Col lg="6">
          <FormGroup
              className={classnames(this.state.departmentState, {
                "input-group-focus": this.state.departmentFocus
              })}
            >
              <Select
                name="department"
                className="react-select"
                placeholder={department}
                classNamePrefix="react-select"
                value={department}                
                onChange={this.onSelectedDepartmentChange}
                options={ departments }
              />
                {this.state.departmentState === "has-danger" ? (
                  <label className="error">Please select a department</label>
                ) : null}
            </FormGroup>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col lg="6">
            <FormGroup>
                <Select
                  name="department"
                  className="react-select"
                  placeholder={userRight}
                  classNamePrefix="react-select"
                  value= { userRight }               
                  onChange={this.onSelectedUserRightsChange}
                  options={ userRights }
                />
              </FormGroup>
            </Col>
        </Row>
        
      </>
    );
  }
}

export default Wizard;

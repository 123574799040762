
import React from "react";
// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Table,
    Row,
    Col
} from "reactstrap";
import Loader from "components/Loader";
import SchemaFooter from "components/SchemaFooter";
import SchemaHeader from "components/SchemaHeader";
import { Base64 } from 'js-base64';
import config from "../config.js";

class Risikovurdering extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            loading: true,
            schemaDataObject: {},
        };
    }

    componentDidMount() {


        //Load schema data
        fetch(config.server.connection.url + "/api/Customer/CustomerSchemaContent?jobId=" + this.props.jobId + "&schemaId=1", {
        //fetch("https://localhost:44347/api/Customer/CustomerSchemaContent?jobId=" + this.props.jobId + "&schemaId=1", {
            method: "GET",
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + Base64.encode(sessionStorage.getItem("id") + ":" + sessionStorage.getItem("value")).replace(/['"]+/g, ''),
                'CompanyID': sessionStorage.getItem("companyID")
            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    console.log(this.props.jobId)
                    this.setState({
                        schemaDataObject: result,
                        loading: false
                    });
                },
                (error) => {
                    this.setState({
                        loading: false,
                        error: true
                    });
                }
            )

    }

    renderProsjektering = () => {
        let table = []
        // loop to create parent
        for (let i = 1; i < this.state.schemaDataObject.Omrade.length; i++) {
            let Jordetablert = "Jordetablert" + i;
            let Ledende = "Ledende" + i;
            let children = []

            //Status ved oppstart
            children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} >{this.state.schemaDataObject.Omrade[i]}</td>)
            children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} >{this.state.schemaDataObject.Kursnr[i]}</td>)

            children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }}><input type="Checkbox" style={{ marginRight: "10px" }} disabled checked={(() => {
                try { if (JSON.stringify(Object.values(this.state.schemaDataObject[Jordetablert])) === '["JordetablertA[' + i + ']"]') { return true } }
                catch{ }
            }
            )()}></input></td>)
            children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }}><input type="Checkbox" style={{ marginRight: "10px" }} disabled checked={(() => {
                try { if (JSON.stringify(Object.values(this.state.schemaDataObject[Jordetablert])) === '["JordetablertC[' + i + ']"]') { return true } }
                catch{ }
            }
            )()}></input></td>)

            //Risiko
            children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }}>Ω {this.state.schemaDataObject.Kontinuitet[i]}</td>)
            children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }}>M {this.state.schemaDataObject.Molinger[i]}</td>)
            children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }}>
                <input type="Checkbox" style={{ marginRight: "10px" }} disabled checked={(() => {
                    try { if (JSON.stringify(Object.values(this.state.schemaDataObject[Ledende])) === '["Ledende' + i + '[' + i + ']"]') { return true } }
                    catch{ }
                }
                )()}></input></td>)
            children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }}>
                <input type="Checkbox" style={{ marginRight: "10px" }} disabled checked={(() => {
                    try { if (JSON.stringify(Object.values(this.state.schemaDataObject[Ledende])) === '["Ikkeledende' + i + '[' + i + ']"]') { return true } }
                    catch{ }
                }
                )()}></input></td>)

            //Planlegging
            children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }}>
                <input type="Checkbox" style={{ marginRight: "10px" }} disabled checked={(() => {
                    try { if ((Object.keys(this.state.schemaDataObject.Kravtiljord).indexOf('' + i) > -1)) { return true } }
                    catch{ }
                }
                )()}></input></td>)

            children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }}>
                <input type="Checkbox" style={{ marginRight: "10px" }} disabled checked={(() => {
                    try { if (JSON.stringify(Object.values(this.state.schemaDataObject.Jordetableres)) === '["Jordetableres[' + i + ']"]') { return true } }
                    catch{ }
                }
                )()}></input></td>)
            children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }}>
                <input type="Checkbox" style={{ marginRight: "10px" }} disabled checked={(() => {
                    try { if ((Object.keys(this.state.schemaDataObject.Kombi).indexOf('' + i) > -1)) { return true } }
                    catch{ }
                }
                )()}></input></td>)
            children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }}>
                <input type="Checkbox" style={{ marginRight: "10px" }} disabled checked={(() => {
                    try { if ((Object.keys(this.state.schemaDataObject.Plate).indexOf('' + i) > -1)) { return true } }
                    catch{ }
                }
                )()}></input></td>)

            children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }}>
                <input type="Checkbox" style={{ marginRight: "10px" }} disabled checked={(() => {
                    try { if ((Object.keys(this.state.schemaDataObject.Moleverdi).indexOf('' + i) > -1)) { return true } }
                    catch{ }
                }
                )()}></input></td>)

            //Create the parent and add the children
            table.push(<tr key={Math.random()}>{children}</tr>)
        }

        table.push(<tr key={Math.random()}><td style={{ border: 0 + 'px', paddingTop: 10 + 'px' }}><input type="checkbox" disabled checked={this.state.schemaDataObject.ikke_aktuelt1} style={{ marginRight: 30 + 'px' }} ></input>Ikke aktuelt</td></tr>)

        return table
    }

    renderForutsetningVernTverrsnitt = () => {
        let table = []
        // loop to create parent
        for (let i = 1; i < this.state.schemaDataObject.Omrade.length; i++) {
            let children = []

            //Produkter
            children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }}>{this.state.schemaDataObject.Omrade[i]}</td>)
            children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }}>{this.state.schemaDataObject.Bruktutstyrr[i]}</td>)
            children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }}>
                <input type="Checkbox" style={{ marginRight: "10px" }} disabled checked={(() => {
                    if (JSON.stringify(this.state.schemaDataObject.Forutsetning_Index) === 2) { return true }
                }
                )()}></input></td>)
            children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }}>
                <input type="Checkbox" style={{ marginRight: "10px" }} disabled checked={(() => {
                    if (Object.values(this.state.schemaDataObject.Forutsetning_Index) === 1) { return true }
                }
                )()}></input></td>)

            //Forelegg.måte NEK tab 52c
            children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }}>
                <input type="Checkbox" style={{ marginRight: "10px" }} disabled checked={(() => {
                    try { if (JSON.stringify(Object.values(this.state.schemaDataObject.Forlegmate1)) === '["ForlegmateA[1]"]') { return true } }
                    catch{ }
                }
                )()}></input></td>)
            children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }}><input type="Checkbox" style={{ marginRight: "10px" }} disabled checked={(() => {
                try { if (JSON.stringify(Object.values(this.state.schemaDataObject.Forlegmate1)) === '["ForlegmateC[1]"]') { return true } }
                catch{ }
            }
            )()}></input></td>)

            //Tverrsnitt NEK tab 52A (mm2)
            children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }}>{this.state.schemaDataObject.Tverrsnitt[i]}</td>)

            //Vern
            children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }}>{this.state.schemaDataObject.Typevern[i]}</td>)
            children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }}><input type="Checkbox" style={{ marginRight: "10px" }} disabled checked={(() => {
                try { if (JSON.stringify(Object.values(this.state.schemaDataObject.Selekivitet1)) === '["Selekivitet1[1]"]') { return true } }
                catch{ }
            }
            )()}></input></td>)

            //Kapslingsgrad (IP NEK 51A) 
            children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }}><input type="Checkbox" style={{ marginRight: "10px" }} disabled checked={(() => {
                try { if (JSON.stringify(Object.values(this.state.schemaDataObject.Kapslingsgrad1)) === '["Kapslingsgrad21[1]"]') { return true } }
                catch{ }
            }
            )()}></input></td>)
            children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }}><input type="Checkbox" style={{ marginRight: "10px" }} disabled checked={(() => {
                try { if (JSON.stringify(Object.values(this.state.schemaDataObject.Kapslingsgrad1)) === '["Kapslingsgrad44[1]"]') { return true } }
                catch{ }
            }
            )()}></input></td>)
            children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }}><input type="Checkbox" style={{ marginRight: "10px" }} disabled checked={(() => {
                try { if (JSON.stringify(Object.values(this.state.schemaDataObject.Kapslingsgrad1)) === '["Kapslingsgrad54[1]"]') { return true } }
                catch{ }
            }
            )()}></input></td>)
            children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }}><input type="Checkbox" style={{ marginRight: "10px" }} disabled checked={(() => {
                try { if (JSON.stringify(Object.values(this.state.schemaDataObject.Kapslingsgrad1)) === '["Kapslingsgrad65[1]"]') { return true } }
                catch{ }
            }
            )()}></input></td>)

            //Brukt norm
            children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }}>
                <input type="Checkbox" style={{ marginRight: "10px" }} disabled checked={(() => {
                    try { if (this.state.schemaDataObject.Bruktnorm1 === '["BruktnormNEK400[1]"]') { return true } }
                    catch{ }
                }
                )()}></input></td>)
            children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }}><input type="Checkbox" style={{ marginRight: "10px" }} disabled checked={(() => {
                try { if (JSON.stringify(Object.values(this.state.schemaDataObject.Bruktnorm1)) === '["Annen[1]"]') { return true } }
                catch{ }
            }
            )()}></input></td>)

            //Create the parent and add the children
            table.push(<tr key={Math.random()}>{children}</tr>)
        }

        table.push(<tr key={Math.random()}><td style={{ border: 0 + 'px', paddingTop: 10 + 'px' }}><input type="checkbox" disabled checked={this.state.schemaDataObject.ikke_aktuelt2} style={{ marginRight: 30 + 'px' }} ></input>Ikke aktuelt</td></tr>)


        return table
    }

    renderVarmebehov = () => {
        let table = []
        // loop to create parent
        for (let i = 1; i < this.state.schemaDataObject.Omrade.length; i++) {
            let children = []
            //sted
            children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} >{this.state.schemaDataObject.Omrade[i]}</td>)
            //areal
            children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} >{this.state.schemaDataObject.Areal[i]}</td>)
            //Skjult 
            let skjult = "Skjult" + i;
            children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }}>
                <input type="Checkbox" style={{ marginRight: "10px" }} disabled checked={(() => {
                    try { if (Object.values(this.state.schemaDataObject[skjult]).indexOf("SkjultA[" + i + "]") > -1) { return true } }
                    catch{ }
                }
                )()}></input></td>)
            //åpen
            children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }}>
                <input type="Checkbox" style={{ marginRight: "10px" }} disabled checked={(() => {
                    try { if (Object.values(this.state.schemaDataObject[skjult]).indexOf("SkjultC[" + i + "]") > -1) { return true } }
                    catch{ }
                }
                )()}></input></td>)
            //effekt
            children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} >{this.state.schemaDataObject.Effekt[i]}</td>)
            //W/m2
            children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} >{this.state.schemaDataObject.Wm2[i]}</td>)
            //spesielle hensyn
            let spesielleHensyn = "Speshensyn" + i;
            children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }}>
                <input type="Checkbox" style={{ marginRight: "10px" }} disabled checked={(() => {
                    try { if ((Object.keys(this.state.schemaDataObject[spesielleHensyn]) > -1)) { return true } }
                    catch{ }
                }
                )()}></input></td>)

            //Create the parent and add the children
            table.push(<tr key={Math.random()}>{children}</tr>)
        }

        table.push(<tr key={Math.random()}><td style={{ border: 0 + 'px', paddingTop: 10 + 'px' }}><input type="checkbox" disabled checked={this.state.schemaDataObject.ikke_aktuelt2} style={{ marginRight: 30 + 'px' }} ></input>Ikke aktuelt</td></tr>)

        return table
    }

    renderSjekkpunktTableRow(jsonRef, jsonCommentRef, sjekkpunktText) {

        if (typeof jsonRef === "undefined") {
            return (
                <tr>
                    <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} > {sjekkpunktText} </td>
                    <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} ><input type="checkbox" disabled checked={false} style={{ marginRight: 30 + 'px' }} ></input></td>
                    <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} ><input type="checkbox" disabled checked={false} style={{ marginRight: 30 + 'px' }} ></input></td>
                    <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} ><input type="checkbox" disabled checked={false} style={{ marginRight: 30 + 'px' }} ></input></td>
                    <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} >{jsonCommentRef}</td>
                </tr>
            )
        }

        if (jsonRef.includes("_Ok_")) {
            return (
                <tr>
                    <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} > {sjekkpunktText} </td>
                    <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} ><input type="checkbox" disabled checked={true} style={{ marginRight: 30 + 'px' }} ></input></td>
                    <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} ><input type="checkbox" disabled checked={false} style={{ marginRight: 30 + 'px' }} ></input></td>
                    <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} ><input type="checkbox" disabled checked={false} style={{ marginRight: 30 + 'px' }} ></input></td>
                    <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} >{jsonCommentRef}</td>
                </tr>
            )
        }
        if (jsonRef.includes("_Nok_")) {
            return (
                <tr>
                    <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} > {sjekkpunktText} </td>
                    <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} ><input type="checkbox" disabled checked={false} style={{ marginRight: 30 + 'px' }} ></input></td>
                    <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} ><input type="checkbox" disabled checked={true} style={{ marginRight: 30 + 'px' }} ></input></td>
                    <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} ><input type="checkbox" disabled checked={false} style={{ marginRight: 30 + 'px' }} ></input></td>
                    <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} >{jsonCommentRef}</td>
                </tr>
            )
        }
        if (jsonRef.includes("_Na_")) {
            return (
                <tr>
                    <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} > {sjekkpunktText} </td>
                    <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} ><input type="checkbox" disabled checked={false} style={{ marginRight: 30 + 'px' }} ></input></td>
                    <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} ><input type="checkbox" disabled checked={false} style={{ marginRight: 30 + 'px' }} ></input></td>
                    <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} ><input type="checkbox" disabled checked={true} style={{ marginRight: 30 + 'px' }} ></input></td>
                    <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} >{jsonCommentRef}</td>
                </tr>
            )
        }
    }

    render() {

        const mystyle = {
            border: "1px solid black",
            padding: "4px"
        };

        const tableStyle = {
            width: "100%",
        };


        const { error, loading, schemaDataObject } = this.state;
        if (loading) {
            return <Loader />
        }

        if (error) {
            return <h2>AUCH!!</h2>
        }

        return (
            <>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>
                                <SchemaHeader></SchemaHeader>

                                <div id="printDiv">
                                    <CardHeader>
                                        <CardTitle tag="h4">SL-31-01 Sjekkliste Risikovurdering - prosjektering</CardTitle>
                                    </CardHeader>

                                    <CardBody>
                                        <b>IK maks skal ligge mellom 1,5 kA — 25 kA. OV ikke over 63A. Ved OV over 63A kan en bytte med ekvivalent utstyr. Hvis ikke må saksbehandler kontaktes.</b>
                                        <br></br><br></br>

                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td><b>Kunde:</b></td>
                                                    <td>{schemaDataObject.Kunde}</td>
                                                    <td><b>Ordrenr:</b></td>
                                                    <td>{schemaDataObject.Ordrenr}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Adresse:</b></td>
                                                    <td>{schemaDataObject.Adresse}</td>
                                                    <td><b>Pnr/Sted:</b></td>
                                                    <td>{schemaDataObject.PnrSted}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Telefon:</b></td>
                                                    <td>{schemaDataObject.Tlfnr}</td>
                                                    <td><b>Epost:</b></td>
                                                    <td>{schemaDataObject.Email}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Anleggstype:</b></td>
                                                    <td>{schemaDataObject.Anleggstype}</td>
                                                    <td><b>Kundenr. e-verk:</b></td>
                                                    <td>{schemaDataObject.Kundenreverk}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>OV størrelse:</b></td>
                                                    <td>{schemaDataObject.OVStorrelse}</td>
                                                    <td><b>Målepunktid:</b></td>
                                                    <td>{schemaDataObject.MalepunktID_}</td>
                                                </tr>
                                            </tbody>
                                        </Table>

                                        <CardHeader>
                                            <CardTitle><b>Spenningssystem:</b></CardTitle>
                                        </CardHeader>
                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td><input type="checkbox" disabled checked={schemaDataObject.IT} style={{ marginRight: 30 + 'px' }} ></input>IT</td>
                                                    <td><input type="checkbox" disabled checked={schemaDataObject.TNCS} style={{ marginRight: 30 + 'px' }} ></input>TN-C-S</td>
                                                    <td><input type="checkbox" disabled checked={schemaDataObject.TNS} style={{ marginRight: 30 + 'px' }} ></input>TN-S</td>
                                                    <td><input type="checkbox" disabled checked={schemaDataObject.TT} style={{ marginRight: 30 + 'px' }} ></input>TT</td>
                                                </tr>
                                            </tbody>
                                        </Table>


                                        {/* ---------------- DATA FRA FORDELINGSNETTET ----------------  */}
                                        <CardHeader>
                                            <CardTitle><b>Data på fordelingsnettet:</b></CardTitle>
                                        </CardHeader>
                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td><input type="checkbox" disabled checked={schemaDataObject.isFree} style={{ marginRight: 30 + 'px' }} ></input>Foreligger fra e-verket</td>
                                                    <td><input type="checkbox" disabled checked={schemaDataObject.isNotFree} style={{ marginRight: 30 + 'px' }} ></input>Foreligger ikke fra e-verket</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ borderTop: 0 + 'px' }} >kA lk3max:</td>
                                                    <td style={{ borderTop: 0 + 'px' }} >{schemaDataObject.lk3max}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ borderTop: 0 + 'px' }} >kA lk3min:</td>
                                                    <td style={{ borderTop: 0 + 'px' }} >{schemaDataObject.lk3min}</td>
                                                </tr>
                                            </tbody>
                                        </Table>

                                        <CardHeader>
                                            <CardTitle><b>Kort anleggsbeskrivelse, oppdragets art:</b></CardTitle>
                                        </CardHeader>
                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td>{schemaDataObject.placeDescription}</td>
                                                </tr>
                                            </tbody>
                                        </Table>

                                        {/* ---------------- PROSJEKTERING ----------------  */}
                                        <CardHeader>
                                            <CardTitle><b>Prosjektering:</b></CardTitle>
                                        </CardHeader>
                                        <table style={tableStyle}>
                                            <tbody>
                                                <tr style={mystyle}>
                                                    <th colSpan="4">Satus ved oppstart</th>
                                                    <th colSpan="4">Risiko</th>
                                                    <th colSpan="5">Planlegging</th>
                                                </tr>
                                                <tr>
                                                    <td style={mystyle} colSpan="2">Område</td>
                                                    <td style={mystyle} colSpan="2">Jord etablert</td>

                                                    <td style={mystyle} colSpan="4">Ledende omgivelser, gulv-vegg</td>

                                                    <td style={mystyle}></td>
                                                    <td style={mystyle} colSpan="4">Type beskyttelse</td>
                                                </tr>
                                                <tr>
                                                    <td style={mystyle}></td>
                                                    <td style={mystyle}>Kurs nr.</td>
                                                    <td style={mystyle}>Ja</td>
                                                    <td style={mystyle}>Nei</td>

                                                    <td style={mystyle}>Kontinuitet</td>
                                                    <td style={mystyle}>Målinger (megges)</td>
                                                    <td style={mystyle}>Ledende</td>
                                                    <td style={mystyle}>Ikke ledende</td>

                                                    <td style={mystyle}>Krav til jord</td>
                                                    <td style={mystyle}>Jord etableres</td>
                                                    <td style={mystyle}>Kombi</td>
                                                    <td style={mystyle}>Plate</td>
                                                    <td style={mystyle}>Måleverdi</td>
                                                </tr>

                                                {this.renderProsjektering()}


                                            </tbody>
                                        </table>


                                        {/* ---------------- FORUTSETNING, VERN OG TVERRSNITT ----------------  */}
                                        <CardHeader>
                                            <CardTitle><b>Forutsetning, vern og tverrsnitt:</b></CardTitle>
                                        </CardHeader>
                                        <table style={tableStyle}>
                                            <tbody>
                                                <tr style={mystyle}>
                                                    <th style={{ border: 1 + "px solid black", padding: 8 + "px" }} colSpan="4">Produkter</th>
                                                    <th style={{ border: 1 + "px solid black", padding: 8 + "px" }} colSpan="2">Foreleg. måte NEK tab 52c</th>
                                                    <th style={{ border: 1 + "px solid black", padding: 8 + "px" }} colSpan="1">Tverrsnitt NEK tab 52A (mm2)</th>
                                                    <th style={{ border: 1 + "px solid black", padding: 8 + "px" }} colSpan="2">Vern</th>
                                                    <th style={{ border: 1 + "px solid black", padding: 8 + "px" }} colSpan="4">Kapslingsgrad (IP NEK 51A)</th>
                                                    <th style={{ border: 1 + "px solid black", padding: 8 + "px" }} colSpan="2">Brukt norm</th>
                                                </tr>

                                                <tr>
                                                    <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} colSpan="1">Sted</td>
                                                    <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} colSpan="1">Brukt utstyr</td>
                                                    <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} colSpan="1">Grossist</td>
                                                    <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} colSpan="1">Kunde</td>

                                                    <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} colSpan="1">A</td>
                                                    <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} colSpan="1">C</td>
                                                    <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} colSpan="1"></td>
                                                    <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} colSpan="1">Type vern</td>
                                                    <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} colSpan="1">Selektivitet</td>

                                                    <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} colSpan="1">21</td>
                                                    <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} colSpan="1">44</td>
                                                    <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} colSpan="1">54</td>
                                                    <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} colSpan="1">65</td>
                                                    <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} colSpan="1">NEK 400</td>
                                                    <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} colSpan="1">Annen</td>
                                                </tr>

                                                {this.renderForutsetningVernTverrsnitt()}

                                            </tbody>
                                        </table>

                                        {/* ---------------- VARMEBEHOV - ÅPEN ELLER SKJULT ----------------  */}
                                        <CardHeader>
                                            <CardTitle><b>Varmebehov - åpen eller skjult:</b></CardTitle>
                                        </CardHeader>
                                        <table style={tableStyle}>
                                            <tbody>
                                                <tr style={mystyle}>
                                                    <th>Sted</th>
                                                    <th>Areal (m2)</th>
                                                    <th>Skjult</th>
                                                    <th>Åpen</th>
                                                    <th>Effekt</th>
                                                    <th>W/m2</th>
                                                    <th>Spesielle hensyn</th>
                                                </tr>

                                                {this.renderVarmebehov()}

                                            </tbody>
                                        </table>


                                        {/* ---------------- SJEKKPUNKTER ----------------  */}
                                        <CardHeader>
                                            <CardTitle><b>Sjekkpunkter:</b></CardTitle>
                                        </CardHeader>
                                        <table style={tableStyle}>
                                            <tbody>
                                                <tr style={mystyle}>
                                                    <th>Sjekkpunkt</th>
                                                    <th>Ja</th>
                                                    <th>Nei</th>
                                                    <th>Ikke aktuelt</th>
                                                    <th>Kommentar</th>
                                                </tr>


                                                {this.renderSjekkpunktTableRow(schemaDataObject.Kontrollpunkter0, schemaDataObject.Kontrollpunkter_Kommentar_0, "Skal oppdraget planlegges og utføres i henhold til NEK 400?")}
                                                {this.renderSjekkpunktTableRow(schemaDataObject.Kontrollpunkter1, schemaDataObject.Kontrollpunkter_Kommentar_1, "Har du tatt hensyn til spesielle krav i TEK?")}
                                                {this.renderSjekkpunktTableRow(schemaDataObject.Kontrollpunkter2, schemaDataObject.Kontrollpunkter_Kommentar_2, "Er de ytre påvirkningene for installasjonen normale?")}
                                                {this.renderSjekkpunktTableRow(schemaDataObject.Kontrollpunkter3, schemaDataObject.Kontrollpunkter_Kommentar_3, "Har du nødvendig informasjon om hva installasjonen skal brukes til?")}
                                                {this.renderSjekkpunktTableRow(schemaDataObject.Kontrollpunkter4, schemaDataObject.Kontrollpunkter_Kommentar_4, "Har du nødvendige opplysninger om nytt og evt. eksisterende utstyr?")}
                                                {this.renderSjekkpunktTableRow(schemaDataObject.Kontrollpunkter5, schemaDataObject.Kontrollpunkter_Kommentar_5, "Har du nødvendig informasjon for å planlegge jordingsanlegget?")}
                                                {this.renderSjekkpunktTableRow(schemaDataObject.Kontrollpunkter6, schemaDataObject.Kontrollpunkter_Kommentar_6, "Har du vurdert om det bør monteres overspenningsbeskyttelse?")}
                                                {this.renderSjekkpunktTableRow(schemaDataObject.Kontrollpunkter7, schemaDataObject.Kontrollpunkter_Kommentar_7, "Kan du se bort fra eventuell risiko forbundet med installasjon av skjult varme?")}
                                                {this.renderSjekkpunktTableRow(schemaDataObject.Kontrollpunkter8, schemaDataObject.Kontrollpunkter_Kommentar_8, "Har du tatt hensyn til eier/brukers krav og forventninger til skjult varmeinstallasjon?")}
                                                {this.renderSjekkpunktTableRow(schemaDataObject.Kontrollpunkter9, schemaDataObject.Kontrollpunkter_Kommentar_9, "Kan du se bort i fra eventuell risiko forbundet med installasjoner i våtrom og/eller badstu?")}
                                                {this.renderSjekkpunktTableRow(schemaDataObject.Kontrollpunkter10, schemaDataObject.Kontrollpunkter_Kommentar_10, "Har du tatt hensyn til spesielle krav til installasjoner i våtrom? (Jordfeilbryter, allpolig brudd etc.)")}
                                                {this.renderSjekkpunktTableRow(schemaDataObject.Kontrollpunkter11, schemaDataObject.Kontrollpunkter_Kommentar_11, "Ved installasjoner i badstu, har du tatt hensyn til spesielle krav dette medfører?")}
                                                {this.renderSjekkpunktTableRow(schemaDataObject.Kontrollpunkter12, schemaDataObject.Kontrollpunkter_Kommentar_12, "Kan du se bort fra eventuell risiko forbundet med utstyr som medfører fare for høye temperaturer (innfelt belysning etc.)?")}
                                                {this.renderSjekkpunktTableRow(schemaDataObject.Kontrollpunkter13, schemaDataObject.Kontrollpunkter_Kommentar_13, "Har du tatt hensyn til forhold som kan oppstå ved installasjon av innfelt belysning?")}
                                                {this.renderSjekkpunktTableRow(schemaDataObject.Kontrollpunkter14, schemaDataObject.Kontrollpunkter_Kommentar_14, "Kan du se bort fra eventuell risiko forbundet med utvendig kabelanlegg i luft og/eller jord?")}
                                                {this.renderSjekkpunktTableRow(schemaDataObject.Kontrollpunkter15, schemaDataObject.Kontrollpunkter_Kommentar_15, "Skal arbeidet utføres uten inngrep i en eksisterende installasjon?")}
                                                {this.renderSjekkpunktTableRow(schemaDataObject.Kontrollpunkter16, schemaDataObject.Kontrollpunkter_Kommentar_16, "Har du klarlagt at eksisterende anlegg ikke har feil/ mangler som er forskriftsstridig eller som kan ha innvirkning på nyinstallasjonen?")}
                                                {this.renderSjekkpunktTableRow(schemaDataObject.Kontrollpunkter17, schemaDataObject.Kontrollpunkter_Kommentar_17, "Har du sikret at nyinstallasjoner ikke svekker sikkerhet og/ eller funksjonalitet i eksisterende anlegg?")}
                                                {this.renderSjekkpunktTableRow(schemaDataObject.Kontrollpunkter18, schemaDataObject.Kontrollpunkter_Kommentar_18, "Er arbeidet koordinert tilstrekkelig med andre fag?")}
                                                {this.renderSjekkpunktTableRow(schemaDataObject.Kontrollpunkter19, schemaDataObject.Kontrollpunkter_Kommentar_19, "Er arbeidet koordinert tilstrekkelig med andre fag?")}
                                                {this.renderSjekkpunktTableRow(schemaDataObject.Kontrollpunkter20, schemaDataObject.Kontrollpunkter_Kommentar_20, "Har personale som skal utføre installasjonen riktig kompetanse?")}
                                                {this.renderSjekkpunktTableRow(schemaDataObject.Kontrollpunkter21, schemaDataObject.Kontrollpunkter_Kommentar_21, "Har du vurdert alle forhold og har tilgang til alle opplysninger som har betydning for denne risikovurderingen?")}
                                                {this.renderSjekkpunktTableRow(schemaDataObject.Kontrollpunkter22, schemaDataObject.Kontrollpunkter_Kommentar_22, "Har du informert eier/bruker om de valg du har lagt til grunn for prosjekteringen?")}

                                            </tbody>
                                        </table>


                                        {/* ---------------- SPESIELLE BEHOV ----------------  */}
                                        <CardHeader>
                                            <CardTitle><b>Spesielle behov:</b></CardTitle>
                                        </CardHeader>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>Det skal jobbes i høyden:</td>
                                                </tr>
                                                <tr>
                                                    <td><input type="checkbox" disabled checked={schemaDataObject.Stilas} style={{ marginRight: 30 + 'px', marginLeft: 30 + 'px' }} ></input>Stilas</td>
                                                </tr>
                                                <tr>
                                                    <td><input type="checkbox" disabled checked={schemaDataObject.Stiger} style={{ marginRight: 30 + 'px', marginLeft: 30 + 'px' }} ></input>Stiger</td>
                                                </tr>
                                                <tr>
                                                    <td><input type="checkbox" disabled checked={schemaDataObject.Lift} style={{ marginRight: 30 + 'px', marginLeft: 30 + 'px' }} ></input>Lift</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <br></br><br></br>

                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td><input type="checkbox" disabled checked={schemaDataObject.Jobbespaellerdeler} style={{ marginRight: 30 + 'px' }} ></input>Jobbes på eller nær spenningssatte deler</td>
                                                </tr>
                                                <tr>
                                                    <td><input type="checkbox" disabled checked={schemaDataObject.Roterende_maskiner} style={{ marginRight: 30 + 'px' }} ></input>Roterende maskiner</td>
                                                </tr>
                                                <tr>
                                                    <td><input type="checkbox" disabled checked={schemaDataObject.Glatt_underlag} style={{ marginRight: 30 + 'px' }} ></input>Glatt underlag</td>
                                                </tr>
                                                <tr>
                                                    <td><input type="checkbox" disabled checked={schemaDataObject.Hengende_last} style={{ marginRight: 30 + 'px' }} ></input>Hengende last</td>
                                                </tr>
                                                <tr>
                                                    <td><input type="checkbox" disabled checked={schemaDataObject.Rotete_pa_plassen} style={{ marginRight: 30 + 'px' }} ></input>Rotete på plassen</td>
                                                </tr>
                                                <tr>
                                                    <td><input type="checkbox" disabled checked={schemaDataObject.Snublekanter} style={{ marginRight: 30 + 'px' }} ></input>Snublekanter</td>
                                                </tr>
                                                <tr>
                                                    <td><input type="checkbox" disabled checked={schemaDataObject.Handtering_tunge} style={{ marginRight: 30 + 'px' }} ></input>Håndering tunge løft</td>
                                                </tr>
                                                <tr>
                                                    <td><input type="checkbox" disabled checked={schemaDataObject.Brannfarlige} style={{ marginRight: 30 + 'px' }} ></input>Brannfarlige stoffer/ avfall</td>
                                                </tr>
                                                <tr>
                                                    <td><input type="checkbox" disabled checked={schemaDataObject.Apen_flamme} style={{ marginRight: 30 + 'px' }} ></input>Åpen flamme</td>
                                                </tr>
                                                <tr>
                                                    <td><input type="checkbox" disabled checked={schemaDataObject.Varme_arbeider} style={{ marginRight: 30 + 'px' }} ></input>Varme arbeider</td>
                                                </tr>
                                                <tr>
                                                    <td><input type="checkbox" disabled checked={schemaDataObject.Avfallshandtering} style={{ marginRight: 30 + 'px' }} ></input>Avfallshandtering</td>
                                                </tr>
                                                <tr>
                                                    <td><input type="checkbox" disabled checked={schemaDataObject.Utslipp_luft} style={{ marginRight: 30 + 'px' }} ></input>Utslipp til vann eller luft</td>
                                                </tr>
                                                <tr>
                                                    <td>Annet: {schemaDataObject.annet}</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <br></br><br></br>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>Tillegsprosjektering</td>
                                                </tr>
                                                <tr>
                                                    <td><input type="checkbox" disabled checked={schemaDataObject.Bytte_stiger_eller_OV} style={{ marginRight: 30 + 'px', marginLeft: 30 + 'px' }} ></input>Bytte stiger eller OV</td>
                                                </tr>
                                                <tr>
                                                    <td><input type="checkbox" disabled checked={schemaDataObject.Nye_kurser} style={{ marginRight: 30 + 'px', marginLeft: 30 + 'px' }} ></input>Nye kurser</td>
                                                </tr>
                                                <tr>
                                                    <td><input type="checkbox" disabled checked={schemaDataObject.Lys} style={{ marginRight: 30 + 'px', marginLeft: 30 + 'px' }} ></input>Lys</td>
                                                </tr>
                                                <tr>
                                                    <td><input type="checkbox" disabled checked={schemaDataObject.Varme} style={{ marginRight: 30 + 'px', marginLeft: 30 + 'px' }} ></input>Varme</td>
                                                </tr>
                                                <tr>
                                                    <td><input type="checkbox" disabled checked={schemaDataObject.Jordingsforhold} style={{ marginRight: 30 + 'px', marginLeft: 30 + 'px' }} ></input>Jordingsforhold</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        {/* ---------------- KOMMENTAR ----------------  */}
                                        <CardHeader>
                                            <CardTitle><b>Kommentar:</b></CardTitle>
                                        </CardHeader>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>{schemaDataObject.comment}</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <br></br><br></br>

                                        {/* ---------------- FOOTER ----------------  */}

                                        <SchemaFooter Date={schemaDataObject.Date} Montor={schemaDataObject.Montor} FooterText={this.props.footerText} CompanyURL={this.props.companyUrl} />

                                    </CardBody>
                                </div>
                            </Card>
                        </Col>
                    </Row>

                </div>
            </>
        )
    }
}

export default Risikovurdering;
import React from "react";

// reactstrap components
import {
    Col,
    Input,
    Label,
    FormGroup,
} from "reactstrap";

class SourceCustomer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: false,
            selectedRow: null,
            rowexpanded: null,
            expanded: {},
            schemaId: null,
            radioSourceSelected: "",
            prisScore: null,
        };
        // this.checkIfSourceChecked = this.checkIfSourceChecked.bind(this);
        // this.handleRadioChecked = this.handleRadioChecked.bind(this);
    }



    componentDidMount() { }

    // checkIfSourceChecked(val) {
    //     console.log("ON SOURCE CUSTOMER")
    //     console.log(val)
    //     if (val === this.state.radioSourceSelected) {
    //         return true;
    //     }
    //     return false;
    // }

    // handleRadioChecked(name, event) {
    //     this.setState({
    //         radioSourceSelected: name,
    //     });
    // }



    render() {



        return (
            <>
                <div className="content">

                    <Label md="12"><b>Hvordan fant du oss?</b></Label>

                    <Col className="checkbox-radios" md="6">
                        <FormGroup check>
                            <Label check>
                                <Input type="radio" name="radio1" id="pris" onChange={(e) => this.props.handleSourceRadioChecked("google")} checked={this.props.checkIfSourceChecked("google")} />
                                <span className="form-radio-sign" />
                                Google
                            </Label>
                        </FormGroup>
                    </Col>

                    <Col className="checkbox-radios" md="6">
                        <FormGroup check>
                            <Label check>
                                <Input type="radio" name="radio2" onChange={(e) => this.props.handleSourceRadioChecked("anbefaling")} checked={this.props.checkIfSourceChecked("anbefaling")} />
                                <span className="form-radio-sign" />
                                Anbefaling
                            </Label>
                        </FormGroup>
                    </Col>

                    <Col className="checkbox-radios" md="6">
                        <FormGroup check>
                            <Label check>
                                <Input type="radio" name="radio3" onChange={(e) => this.props.handleSourceRadioChecked("gulesider")} checked={this.props.checkIfSourceChecked("gulesider")} />
                                <span className="form-radio-sign" />
                                Gulesider
                            </Label>
                        </FormGroup>
                    </Col>

                    <Col className="checkbox-radios" md="6">
                        <FormGroup check>
                            <Label check>
                                <Input type="radio" name="radio4" onChange={(e) => this.props.handleSourceRadioChecked("1881")} checked={this.props.checkIfSourceChecked("1881")} />
                                <span className="form-radio-sign" />
                                1881
                            </Label>
                        </FormGroup>
                    </Col>

                    <Col className="checkbox-radios" md="6">
                        <FormGroup check>
                            <Label check>
                                <Input type="radio" name="radio5" onChange={(e) => this.props.handleSourceRadioChecked("avis")} checked={this.props.checkIfSourceChecked("avis")} />
                                <span className="form-radio-sign" />
                                Avis
                            </Label>
                        </FormGroup>
                    </Col>

                    <Col className="checkbox-radios" md="6">
                        <FormGroup check>
                            <Label check>
                                <Input type="radio" name="radio6" onChange={(e) => this.props.handleSourceRadioChecked("bil")} checked={this.props.checkIfSourceChecked("bil")} />
                                <span className="form-radio-sign" />
                                Sett våre biler
                            </Label>
                        </FormGroup>
                    </Col>

                    <Col className="checkbox-radios" md="6">
                        <FormGroup check>
                            <Label check>
                                <Input type="radio" name="radio7" onChange={(e) => this.props.handleSourceRadioChecked("kjenner")} checked={this.props.checkIfSourceChecked("kjenner")} />
                                <span className="form-radio-sign" />
                                Kjenner elektrikeren
                            </Label>
                        </FormGroup>
                    </Col>

                    <Col className="checkbox-radios" md="3">
                        <FormGroup check>
                            <Label check>
                                <Input type="radio" name="radio8" onChange={(e) => this.props.handleSourceRadioChecked("annet")} checked={this.props.checkIfSourceChecked("annet")} />
                                <span className="form-radio-sign" />
                                Annet
                            </Label>
                        </FormGroup>
                    </Col>

                </div>
            </>
        );
    }
}

export default SourceCustomer;



import React from "react";
import classnames from "classnames";
import ReactDatetime from "react-datetime";

// reactstrap components
import {
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";

class Wizard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      firstNameState: "",
      lastName: "",
      lastNameState: "",      
      startDate: "",
      startDateState: ""
    };
  }
  

  // function that verifies if a string has a given length or not
  verifyLength  = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };

  change = (event, stateName, type, stateNameEqualTo) => {
    switch (type) {
      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ 
            [stateName + "State"]: "has-success",
            [stateName]: event.target.value,
        });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      case "startDate":
        this.setState({
           [stateName + "State"]: "has-success",
           startDate: event._d.toDateString()
          });
        break;
      default:
        break;
    }
    //this.setState({ [stateName]: event.target.value });
  };

  isValidated = () => {
    if (
      this.state.firstNameState === "has-success" &&
      this.state.lastNameState === "has-success" &&
      this.state.startDateState === "has-success"
    ) {
      return true;
    } else {
      if (this.state.firstNameState !== "has-success") {
        this.setState({ firstNameState: "has-danger" });
      }
      if (this.state.lastNameState !== "has-success") {
        this.setState({ lastNameState: "has-danger" });
      }
      if (this.state.startDateState !== "has-success") {
        this.setState({ startDateState: "has-danger" });
      }
      return false;
    }
  };

  render() {
    return (
      <>
        <Row className="justify-content-center">
          <Col sm="12">
            <h5 className="info-text">Just a few more details...</h5>
          </Col>
          <Col sm="5">
            <InputGroup
                className={classnames(this.state.firstNameState, {
                  "input-group-focus": this.state.firstNameState
                })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="nc-icon nc-tag-content" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  name="phone"
                  placeholder="First name (required)"
                  type="text"
                  onChange={e => this.change(e, "firstName", "length", 2)}
                  onFocus={e => this.setState({ firstNameFocus: true })}
                  onBlur={e => this.setState({ firstNameFocus: false })}
                />
                {this.state.firstNameState === "has-danger" ? (
                  <label className="error">This field is required. Minumum 2 letters.</label>
                ) : null}
              </InputGroup>
          </Col>

          <Col sm="5">
            <InputGroup
                className={classnames(this.state.lastNameState, {
                  "input-group-focus": this.state.lastNameState
                })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="nc-icon nc-tag-content" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  name="phone"
                  placeholder="Last name (required)"
                  type="text"
                  onChange={e => this.change(e, "lastName", "length", 2)}
                  onFocus={e => this.setState({ lastNameFocus: true })}
                  onBlur={e => this.setState({ lastNameFocus: false })}
                />
                {this.state.lastNameState === "has-danger" ? (
                  <label className="error">This field is required. Minumum 2 letters.</label>
                ) : null}
              </InputGroup>
          </Col>

          <Col md="5">
            <label>Start date</label>
              <ReactDatetime
                inputProps={{
                  className: "form-control",
                  placeholder: "Click to selct (required)"                  
                }}
                timeFormat={false}
                closeOnSelect={true}
                onChange={e => this.change(e, "startDate", "startDate")}
                //onFocus={e => this.setState({ startDateFocus: true })}
                onBlur={e => this.setState({ startDateFocus: false })}
                
              />
               {this.state.startDateState === "has-danger" ? (
                  <label className="error">An employee start date is required.</label>
                ) : null}
          </Col>

          <Col sm="5">
         
          </Col>

        </Row>
      </>
    );
  }
}

export default Wizard;

import React from "react";

// reactstrap components
import {
    Col,
    Input,
    Label,
    FormGroup,
} from "reactstrap";

class PriceSubReasons extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            jobsLoading: false,
            error: false,
            customerJobSchemasArray: [],
            customerJobArray: [],
            selectedRow: null,
            rowexpanded: null,
            expanded: {},
            jobId: "63050",
            schemaId: null,
            txtOtherReasonDisabled: true,
            showPriceSubReasons: false,
            radioSelected: "",
            prisScore: null,
            kvalitetScore: null,
            effektivitetScore: null,
            palitelighetScore: null,
        };
    }



    componentDidMount() {

    }

    setPrisSubReason(val) {

        if (val !== "annet") {
            this.setState({
                radioSelected: val,
                txtOtherReasonDisabled: true,
            });
            return;
        } else {
            this.setState({
                radioSelected: val,
                txtOtherReasonDisabled: false,
            });
            return;
        }
    }

    checkIfChecked(val) {
        if (val === this.state.radioSelected) {
            return true;
        }
        return false;
    }

    handleChange(name, event) {
        this.annetText = event.target.value;
    }


    render() {



        return (
            <>
                <div className="content">

                    <Label md="12">Hvorfor er du så misfornøyd med prisen?</Label>

                    <Col className="checkbox-radios" md="3">
                        <FormGroup check>
                            <Label check>
                                <Input type="radio" name="radio1" id="pris" onChange={(e)=> this.props.handleRadioChecked("pris")} checked={this.props.checkIfChecked("pris")} />
                                <span className="form-radio-sign" />
                                Høy pris
                            </Label>
                        </FormGroup>
                    </Col>

                    <Col className="checkbox-radios" md="3">
                        <FormGroup check>
                            <Label check>
                                <Input type="radio" name="radio1" onChange={(e)=> this.props.handleRadioChecked("uforutsigbart")} checked={this.props.checkIfChecked("uforutsigbart")} />
                                <span className="form-radio-sign" />
                                Uforutsigbart
                            </Label>
                        </FormGroup>
                    </Col>

                    <Col className="checkbox-radios" md="3">
                        <FormGroup check>
                            <Label check>
                                <Input type="radio" name="radio1" onChange={(e)=> this.props.handleRadioChecked("kvalitet")} checked={this.props.checkIfChecked("kvalitet")}  />
                                <span className="form-radio-sign" />
                                Ikke i samsvar med kvalitet
                            </Label>
                        </FormGroup>
                    </Col>

                    <Col className="checkbox-radios" md="3">
                        <FormGroup check>
                            <Label check>
                                <Input type="radio" name="radio1" onChange={(e)=> this.props.handleRadioChecked("annet")} checked={this.props.checkIfChecked("annet")} />
                                <span className="form-radio-sign" />
                                Annet
                            </Label>
                        </FormGroup>
                    </Col>


                    <Col className="checkbox-radios" md="6">
                        <FormGroup>
                            <Input type="text" value={this.annetText} disabled={this.props.annetEnabled} onChange={(event)=> this.props.handleTxtType(event.target.value)} />
                        </FormGroup>
                    </Col>

                </div>
            </>
        );
    }
}

export default PriceSubReasons;



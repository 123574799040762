
import React from "react";
// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Table,
    Row,
    Col
} from "reactstrap";
import Loader from "components/Loader";
import SchemaFooter from "components/SchemaFooter";
import SchemaHeader from "components/SchemaHeader";
import { Base64 } from 'js-base64';


class Reklamasjon extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            loading: true,
            schemaDataObject: {},
            avvikCol1Array: [],
            avvikCol2Array: [],
            tiltakArray: [],
            elNrArray: [],
            produktNavnArray: [],
            antallArray: [],
            faktureresArray: [],
        };
    }

    componentDidMount() {

        //Load schema data
        fetch("https://rokeagruppenapi.azurewebsites.net/api/Customer/CustomerSchemaContent?jobId=" + this.props.jobId + "&schemaId=8", {
            method: "GET",
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + Base64.encode(sessionStorage.getItem("id") + ":" + sessionStorage.getItem("value")).replace(/['"]+/g, ''),
                'CompanyID': sessionStorage.getItem("companyID")
            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        loading: false,
                        schemaDataObject: result,
                        kundensBeskrivelseArray: result.Description_1,
                        selskapBeskrivelseArray: result.Description_2,
                        aarsakArray: result.error,
                        montasjedatoArray: result.montasjedato,
                        elNrArray: result.elNr,
                        produktNavnArray: result.desc_,
                        antallArray: result.qty,
                        faktureresArray: result.Faktureres,
                    });
                },
                (error) => {
                    this.setState({
                        loading: false,
                        error: true
                    });
                }
            )
    }


    renderReklamasjoner = () => {

        let table = []
        // loop to create parent
        for (let i = 1; i < this.state.kundensBeskrivelseArray.length; i++) {
            let children = []
            children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} >{this.state.kundensBeskrivelseArray[i]}</td>)
            children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} >{this.state.selskapBeskrivelseArray[i]}</td>)

            children.push(
                <td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} >{(() => {
                    if (this.state.aarsakArray[i] === "errora[1]") { return "Produktfeil" }
                    if (this.state.aarsakArray[i] === "errorb[2]") { return "Kunden manglet kunnskap" }
                    if (this.state.aarsakArray[i] === "errorc[3]") { return "Montørfeil" }
                    if (this.state.aarsakArray[i] === "errord[4]") { return "Arbeid" }
                }
                )()}</td>
            )
            children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} >{this.state.montasjedatoArray[i]}</td>)
            //Create the parent and add the children
            table.push(<tr key={Math.random()}>{children}</tr>)
        }
        return table
    }


    renderProdukter = () => {

        let table = []
        // loop to create parent
        for (let i = 1; i < this.state.elNrArray.length; i++) {
            let children = []
            children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} >{this.state.elNrArray[i]}</td>)
            children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} >{this.state.produktNavnArray[i]}</td>)
            children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} >{this.state.antallArray[i]}</td>)
            children.push(
                <td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} >{(() => {
                    if (this.state.faktureresArray[i] === "Faktureres[1]") { return "Ja" }
                    if (this.state.faktureresArray[i] !== "Faktureres[1]") { return "Nei" }
                }
                )()}</td>
            )
            //Create the parent and add the children
            table.push(<tr key={Math.random()}>{children}</tr>)
        }
        return table
    }



    render() {

        const { error, loading, schemaDataObject } = this.state;
        if (loading) {
            return <Loader />
        }

        if (error) {
            return <h2>AUCH!!</h2>
        }

        return (
            <>
                <div className="content">

                    <Row>
                        <Col md="12">
                            <Card>
                                <SchemaHeader></SchemaHeader>

                                <div id="printDiv">
                                    {/* ---------------- REKLAMASJON ----------------  */}
                                    <CardHeader>
                                        <CardTitle tag="h4">Reklamasjon</CardTitle>
                                    </CardHeader>

                                    <CardBody>
                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td style={{ width: "25%" }} ><b>Kundens beskrivelse:</b></td>
                                                    <td style={{ width: "25%" }} ><b>Leverandør beskrivelse:</b></td>
                                                    <td style={{ width: "25%" }} ><b>Årsak:</b></td>
                                                    <td style={{ width: "25%" }} ><b>Montørdato:</b></td>
                                                </tr>
                                                {this.renderReklamasjoner()}
                                            </tbody>
                                        </Table>


                                        {/* ---------------- PRODUKTER ----------------  */}
                                        <CardHeader>
                                            <CardTitle tag="h4">Produkter</CardTitle>
                                        </CardHeader>

                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td style={{ width: "25%" }} ><b>El-nummer:</b></td>
                                                    <td style={{ width: "25%" }} ><b>Produktnavn:</b></td>
                                                    <td style={{ width: "25%" }} ><b>Antall:</b></td>
                                                    <td style={{ width: "25 %" }} ><b>Faktureres:</b></td>
                                                </tr>
                                                {this.renderProdukter()}
                                            </tbody>
                                        </Table>

                                        {/* ---------------- NÅR BLIR FEILEN ELLER SKADEN RETTET OPP ----------------  */}
                                        <CardHeader>
                                            <CardTitle tag="h4">Når blir feilen eller skaden rettet opp?</CardTitle>
                                        </CardHeader>

                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td style={{ width: "25%", borderTop: 0 + "px" }} ><b> - Vi reparerer feil og skader så snart som mulig og senest innen 72 timer.</b></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: "25%", borderTop: 0 + "px" }} ><b> - Ved fare for liv eller helse retter vi feilen umiddelbart.</b></td>
                                                </tr>
                                            </tbody>
                                        </Table>

                                        {/* ---------------- MÅ DU BETALE ----------------  */}
                                        <CardHeader>
                                            <CardTitle tag="h4">Må du betale?</CardTitle>
                                        </CardHeader>

                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td style={{ width: "25%", borderTop: 0 + "px" }} ><b> - Ved reklamasjon trenger du ikke betale.</b></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: "25%", borderTop: 0 + "px" }} ><b> - Hvis det viser seg at feilen eller skaden ikke er reklamasjon, vil våre vanlige priser gjelde.</b></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: "25%", borderTop: 0 + "px" }} ><b> - Får du en annen leverandør til å reparere feilen eller skaden, må du dekke disse utgiftene selv.</b></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: "25%", borderTop: 0 + "px" }} ><b> - Det er 5 års garanti for privatpersoner, 1 år for firmakunder.</b></td>
                                                </tr>
                                            </tbody>
                                        </Table>

                                        {/* ---------------- MONTØR ----------------  */}
                                        <hr></hr>
                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td style={{ width: "10%", borderTop: 1 + "px", color: "black" }} ><b>Dato</b></td>
                                                    <td style={{ borderTop: 1 + "px" }} >11.12.2010</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: "25%", borderTop: 0 + "px" }} ><b>Montør</b></td>
                                                    <td style={{ borderTop: 0 + "px" }} > {schemaDataObject.Montor} </td>
                                                </tr>
                                            </tbody>
                                        </Table>

                                        <br></br><br></br>

                                        {/* ---------------- FOOTER ----------------  */}
                                        <SchemaFooter Date={schemaDataObject.Date} Montor={schemaDataObject.Montor} FooterText={this.props.footerText} CompanyURL={this.props.companyUrl} />
                                    </CardBody>
                                </div>
                            </Card>
                        </Col>
                    </Row>

                </div>
            </>
        )

    }
}

export default Reklamasjon;
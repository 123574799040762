
import React from "react";
// reactstrap components
import Loader from "components/Loader";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col
} from "reactstrap";
import SchemaHeader from "components/SchemaHeader";
import SchemaFooter from "components/SchemaFooter";
import { Base64 } from 'js-base64';


class Samsvar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      schemaDataObject: {},

      nyanlegg: false,
      utvidelse: false,
      endring: false,
      vedlikehold: false,
      reparasjon: false,
      nek400: false,
      normerAndre: false,
      kursfortegnelse: false,
      tegninger: false,
      bruksanvisninger: false,
      sluttkontrollskjema: false,
      dokumentasjonomvarmeanlegg: false,
      risikovurdering: false,
      dokumentasjonAnnet: false,
    };
  }

  componentDidMount() {

    this.setState({
      loading: true,
    });

    //Load schema data
    fetch("https://rokeagruppenapi.azurewebsites.net/api/Customer/CustomerSchemaContent?jobId=" + this.props.jobId + "&schemaId=2", {
      method: "GET",
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + Base64.encode(sessionStorage.getItem("id") + ":" + sessionStorage.getItem("value")).replace(/['"]+/g, ''),
        'CompanyID': sessionStorage.getItem("companyID")
      },
    })
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result)
          this.setState({
            schemaDataObject: result,
            loading: false
          });
        },
        (error) => {
          this.setState({
            loading: false,
            error: true
          });
        }
      )
  }


  render() {

    const { error, loading, schemaDataObject } = this.state;

    if (loading) {
      return <Loader />
    }

    if (error) {
      return <h2>AUCH!!</h2>
    }

    return (
      <>
        <div className="content">

          <Row>
            <Col md="12">
              <Card>

                <SchemaHeader></SchemaHeader>

                <div id="printDiv">
                  <CardHeader>
                    <CardTitle tag="h4">SL-36-01 Erklæring om samsvar</CardTitle>
                  </CardHeader>



                  <CardBody>
                    <b>Erklæring om at planlegging/ utførelse av elektriske anlegg er i samsvar med sikkerhetskravene i Forskrift om elektriske lavspenningsanlegg § 12.</b>
                    <br></br><br></br>
                    <i>Erklæring om samsvar skal i følge § 12 i Forskrift for elektriske lavspenningsanlegg oppbevares av eier av anlegget i hele anleggets levetid. Dette er et verdipapir og en garanti på at installasjonen er kontrollert og prøvet for å sikre at forskriftens krav er fulgt.</i>
                    <br></br><br></br>

                    <Table>
                      <tbody>
                        <tr>
                          <td><b>Kunde:</b></td>
                          <td>{schemaDataObject.Kunde}</td>
                          <td><b>Ordrenr:</b></td>
                          <td>{schemaDataObject.Ordrenr}</td>
                        </tr>
                        <tr>
                          <td><b>Adresse:</b></td>
                          <td>{schemaDataObject.Adresse}</td>
                          <td><b>Pnr/Sted:</b></td>
                          <td>{schemaDataObject.PnrSted}</td>
                        </tr>
                        <tr>
                          <td><b>Telefon:</b></td>
                          <td>{schemaDataObject.Tlfnr}</td>
                          <td><b>Epost:</b></td>
                          <td>{schemaDataObject.Epost}</td>
                        </tr>
                        <tr>
                          <td><b>Anleggstype:</b></td>
                          <td>{schemaDataObject.Anleggstype}</td>
                          <td><b>Kundenreverk:</b></td>
                          <td>{schemaDataObject.Kundenreverk}</td>
                        </tr>
                        <tr>
                          <td><b>OV Størrelse:</b></td>
                          <td>{schemaDataObject.OVStorrelse}</td>
                          <td><b>Målepunktid:</b></td>
                          <td>{this.state.MalepunktID_}</td>
                        </tr>
                      </tbody>
                    </Table>

                    <CardHeader>
                      <CardTitle><b>Anleggsinformasjon:</b></CardTitle>
                      <CardTitle>Utførelse:</CardTitle>
                    </CardHeader>
                    <Table>
                      <tbody>
                        <tr>
                          <td><input type="checkbox" disabled checked={schemaDataObject.Nyanlegg} style={{ marginRight: 30 + 'px' }} ></input>Nyanlegg</td>
                          <td><input type="checkbox" disabled checked={schemaDataObject.Utvidelse} style={{ marginRight: 30 + 'px' }} ></input>Utvidelse</td>
                          <td><input type="checkbox" disabled checked={schemaDataObject.Endring} style={{ marginRight: 30 + 'px' }} ></input>Endring</td>
                          <td><input type="checkbox" disabled checked={schemaDataObject.Vedlikehold} style={{ marginRight: 30 + 'px' }} ></input>Vedlikehold</td>
                          <td><input type="checkbox" disabled checked={schemaDataObject.Reparasjon} style={{ marginRight: 30 + 'px' }} ></input>Reparasjon</td>
                        </tr>
                      </tbody>
                    </Table>

                    <CardHeader>
                      <CardTitle>Anvendte normer:</CardTitle>
                    </CardHeader>
                    <Table>
                      <tbody>
                        <tr>
                          <td><input type="checkbox" disabled checked={schemaDataObject.NEK400_2006} style={{ marginRight: 30 + 'px' }} ></input>NEK 400</td>
                          <td><input type="checkbox" disabled checked={schemaDataObject.Andre} style={{ marginRight: 30 + 'px' }} ></input>Andre: {schemaDataObject.AndreText}</td>
                        </tr>
                      </tbody>
                    </Table>

                    <CardHeader>
                      <CardTitle>Beskrivelse av anlegget/ arbeidet:</CardTitle>
                    </CardHeader>
                    <Table>
                      <tbody>
                        <tr>
                          <td>{schemaDataObject.Beskrivelse}</td>
                        </tr>
                      </tbody>
                    </Table>

                    <CardHeader>
                      <CardTitle>Overlevert dokumentajson:</CardTitle>
                    </CardHeader>
                    <Table>
                      <tbody>
                        <tr>
                          <td><input type="checkbox" disabled checked={schemaDataObject.Kursfortegnelse} style={{ marginRight: 30 + 'px' }} ></input>Kursfortegnelse</td>
                          <td><input type="checkbox" disabled checked={schemaDataObject.Tegninger} style={{ marginRight: 30 + 'px' }} ></input>Tegninger</td>
                          <td><input type="checkbox" disabled checked={schemaDataObject.Bruksanvisninger} style={{ marginRight: 30 + 'px' }} ></input>Bruksanvisninger</td>
                        </tr>
                        <tr>
                          <td style={{ borderTop: 0 + 'px' }} ><input type="checkbox" disabled checked={schemaDataObject.Sluttkontrollskjema} style={{ marginRight: 30 + 'px' }} ></input>Sluttkontrollskjema</td>
                          <td style={{ borderTop: 0 + 'px' }} ><input type="checkbox" disabled checked={schemaDataObject.Dokumentasjonomvarmeanlegg} style={{ marginRight: 30 + 'px' }} ></input>Dokumentasjon om varmeanlegg</td>
                        </tr>
                        <tr>
                          <td style={{ borderTop: 0 + 'px' }} ><input type="checkbox" disabled checked={schemaDataObject.Risikovurdering} style={{ marginRight: 30 + 'px' }} ></input>Risikovurdering/ prosjektering</td>
                          <td style={{ borderTop: 0 + 'px' }} ><input type="checkbox" disabled checked={schemaDataObject.DokumentasjonAnnet} style={{ marginRight: 30 + 'px' }} ></input>Annet:</td>
                        </tr>
                      </tbody>
                    </Table>

                    <br></br><br></br>

                    <SchemaFooter Date={schemaDataObject.Date} Montor={schemaDataObject.Montor} FooterText={this.props.footerText} CompanyURL={this.props.companyUrl} />

                  </CardBody>
                </div>
              </Card>
            </Col>
          </Row>

        </div>
      </>
    )

  }
}

export default Samsvar;
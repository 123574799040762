
import React from "react";
// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Table,
    Row,
    Col
} from "reactstrap";
import Loader from "components/Loader";
import SchemaFooter from "components/SchemaFooter";
import SchemaHeader from "components/SchemaHeader";
import { Base64 } from 'js-base64';

class SjekklisteRisiko extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            loading: true,
            schemaDataObject: {},
        };
    }

    componentDidMount() {

        //Load schema data
        fetch("https://rokeagruppenapi.azurewebsites.net/api/Customer/CustomerSchemaContent?jobId=" + this.props.jobId + "&schemaId=11", {
            method: "GET",
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + Base64.encode(sessionStorage.getItem("id") + ":" + sessionStorage.getItem("value")).replace(/['"]+/g, ''),
                'CompanyID': sessionStorage.getItem("companyID")
            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        loading: false,
                        schemaDataObject: result,
                    });
                },
                (error) => {
                    this.setState({
                        loading: false,
                        error: true
                    });
                }
            )
    }

    renderSjekkpunktTableRow(jsonRef, jsonCommentRef, sjekkpunktText) {
        if (typeof jsonRef === "undefined") {
            return (
                <tr>
                    <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} > {sjekkpunktText} </td>
                    <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} ><input type="checkbox" disabled checked={false} style={{ marginRight: 30 + 'px' }} ></input></td>
                    <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} ><input type="checkbox" disabled checked={false} style={{ marginRight: 30 + 'px' }} ></input></td>
                    <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} ><input type="checkbox" disabled checked={false} style={{ marginRight: 30 + 'px' }} ></input></td>
                    <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} >{jsonCommentRef}</td>
                </tr>
            )
        }
        if (jsonRef.includes("_Ok_")) {
            return (
                <tr>
                    <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} > {sjekkpunktText} </td>
                    <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} ><input type="checkbox" disabled checked={true} style={{ marginRight: 30 + 'px' }} ></input></td>
                    <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} ><input type="checkbox" disabled checked={false} style={{ marginRight: 30 + 'px' }} ></input></td>
                    <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} ><input type="checkbox" disabled checked={false} style={{ marginRight: 30 + 'px' }} ></input></td>
                    <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} >{jsonCommentRef}</td>
                </tr>
            )
        }
        if (jsonRef.includes("_Nok_")) {
            return (
                <tr>
                    <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} > {sjekkpunktText} </td>
                    <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} ><input type="checkbox" disabled checked={false} style={{ marginRight: 30 + 'px' }} ></input></td>
                    <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} ><input type="checkbox" disabled checked={true} style={{ marginRight: 30 + 'px' }} ></input></td>
                    <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} ><input type="checkbox" disabled checked={false} style={{ marginRight: 30 + 'px' }} ></input></td>
                    <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} >{jsonCommentRef}</td>
                </tr>
            )
        }
        if (jsonRef.includes("_Na_")) {
            return (
                <tr>
                    <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} > {sjekkpunktText} </td>
                    <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} ><input type="checkbox" disabled checked={false} style={{ marginRight: 30 + 'px' }} ></input></td>
                    <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} ><input type="checkbox" disabled checked={false} style={{ marginRight: 30 + 'px' }} ></input></td>
                    <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} ><input type="checkbox" disabled checked={true} style={{ marginRight: 30 + 'px' }} ></input></td>
                    <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} >{jsonCommentRef}</td>
                </tr>
            )
        }
    }


    render() {

        const mystyle = {
            border: "1px solid black",
            padding: "4px"
        };

        const tableStyle = {
            width: "100%",
        };


        const { error, loading, schemaDataObject } = this.state;
        if (loading) {
            return <Loader />
        }

        if (error) {
            return <h2>AUCH!!</h2>
        }

        return (
            <>
                <div className="content">

                    <Row>
                        <Col md="12">
                            <Card>
                                <SchemaHeader></SchemaHeader>

                                <div id="printDiv">

                                    <CardHeader>
                                        <CardTitle tag="h4">SL-31-01 Sjekkliste Risikovurdering (bedriftsintern)</CardTitle>
                                    </CardHeader>

                                    <CardBody>
                                        <CardHeader>
                                            <CardTitle><b>Elektroinstallatør</b></CardTitle>
                                        </CardHeader>
                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td><b>Firma:</b></td>
                                                    <td>{schemaDataObject.Company[0]}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ borderTop: 0 + 'px' }} ><b>Adresse:</b></td>
                                                    <td style={{ borderTop: 0 + 'px' }} >{schemaDataObject.Company[1]}</td>

                                                </tr>
                                                <tr>
                                                    <td style={{ borderTop: 0 + 'px' }} ><b>Pnr./Sted:</b></td>
                                                    <td style={{ borderTop: 0 + 'px' }} >{schemaDataObject.Company[2]}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ borderTop: 0 + 'px' }} ><b>Telefon (døgnvakt):</b></td>
                                                    <td style={{ borderTop: 0 + 'px' }} >{schemaDataObject.Company[3]}</td>
                                                </tr>
                                            </tbody>
                                        </Table>

                                        <CardHeader>
                                            <CardTitle><b>Kunde:</b></CardTitle>
                                        </CardHeader>
                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td><b>Firma/ Navn:</b></td>
                                                    <td>{schemaDataObject.Company[0]}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ borderTop: 0 + 'px' }} ><b>Kontaktperson:</b></td>
                                                    <td style={{ borderTop: 0 + 'px' }} >{schemaDataObject.Client[0]}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ borderTop: 0 + 'px' }} ><b>Kontaktperson:</b></td>
                                                    <td style={{ borderTop: 0 + 'px' }} >{schemaDataObject.Client[1]}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ borderTop: 0 + 'px' }} ><b>Adresse:</b></td>
                                                    <td style={{ borderTop: 0 + 'px' }} >{schemaDataObject.Client[2]}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ borderTop: 0 + 'px' }} ><b>Pnr./ Sted:</b></td>
                                                    <td style={{ borderTop: 0 + 'px' }} >{schemaDataObject.Client[3]}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ borderTop: 0 + 'px' }} ><b>Telefon:</b></td>
                                                    <td style={{ borderTop: 0 + 'px' }} >{schemaDataObject.Client[4]}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ borderTop: 0 + 'px' }} ><b>Epost:</b></td>
                                                    <td style={{ borderTop: 0 + 'px' }} >{schemaDataObject.Client[5]}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ borderTop: 0 + 'px' }} ><b>Kundenummer:</b></td>
                                                    <td style={{ borderTop: 0 + 'px' }} >{schemaDataObject.Client[6]}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ borderTop: 0 + 'px' }} ><b>Ordrenummer:</b></td>
                                                    <td style={{ borderTop: 0 + 'px' }} >{schemaDataObject.Client[7]}</td>
                                                </tr>
                                            </tbody>
                                        </Table>


                                        {/* ---------------- SJEKKPUNKTER ----------------  */}
                                        <CardHeader>
                                            <CardTitle><b>Sjekklistens omfang:</b></CardTitle>
                                        </CardHeader>
                                        <br></br><br></br>
                                        <CardHeader>
                                            <CardTitle><b>Sjekkpunkter:</b></CardTitle>
                                        </CardHeader>
                                        <table style={tableStyle}>
                                            <tbody>
                                                <tr style={mystyle}>
                                                    <th>Sjekkpunkt</th>
                                                    <th>Ja</th>
                                                    <th>Nei</th>
                                                    <th>Ikke aktuelt</th>
                                                    <th>Kommentar</th>
                                                </tr>

                                                {this.renderSjekkpunktTableRow(schemaDataObject.Kontrollpunkter0, schemaDataObject.Kontrollpunkter_Kommentar_0, "Kan oppdraget utføres uten spesielle HMS-prosedyrer?")}
                                                {this.renderSjekkpunktTableRow(schemaDataObject.Kontrollpunkter1, schemaDataObject.Kontrollpunkter_Kommentar_1, "Har elektrikeren tilgang til førstehjelpsutstyr og nødvendig personlig verneutstyr for å utføre oppdraget?")}
                                                {this.renderSjekkpunktTableRow(schemaDataObject.Kontrollpunkter2, schemaDataObject.Kontrollpunkter_Kommentar_2, "Er arbeidsområdet sikret og tilgjengelig, og har du sikret at annet arbeide i området ikke kan forårsake farlige hendelser?")}
                                                {this.renderSjekkpunktTableRow(schemaDataObject.Kontrollpunkter3, schemaDataObject.Kontrollpunkter_Kommentar_3, "Kan elektrikeren se bort fra eventuell risiko forbundet med varme arbeider?")}
                                                {this.renderSjekkpunktTableRow(schemaDataObject.Kontrollpunkter4, schemaDataObject.Kontrollpunkter_Kommentar_4, "Har elektrikeren nødvendig sertifikater, kurs etc. for å utføre varme arbeider?")}
                                                {this.renderSjekkpunktTableRow(schemaDataObject.Kontrollpunkter5, schemaDataObject.Kontrollpunkter_Kommentar_5, "Kan elektrikeren se bort fra eventuell risiko forbundet med arbeid i høyden?")}
                                                {this.renderSjekkpunktTableRow(schemaDataObject.Kontrollpunkter6, schemaDataObject.Kontrollpunkter_Kommentar_6, "Har elektrikeren nødvendig sertifikater, kurs etc. for å utføre arbeid i høyden?")}
                                                {this.renderSjekkpunktTableRow(schemaDataObject.Kontrollpunkter7, schemaDataObject.Kontrollpunkter_Kommentar_7, "Kan oppdraget gjennomføres spenningsløst?")}
                                                {this.renderSjekkpunktTableRow(schemaDataObject.Kontrollpunkter8, schemaDataObject.Kontrollpunkter_Kommentar_8, "Har elektrikeren nødvendig opplæring i AUS?")}
                                                {this.renderSjekkpunktTableRow(schemaDataObject.Kontrollpunkter9, schemaDataObject.Kontrollpunkter_Kommentar_9, "Er det tatt høyde for at løse deler (f.eks. jernspon eller smuss) kan skape farlige hendelser?")}
                                                {this.renderSjekkpunktTableRow(schemaDataObject.Kontrollpunkter10, schemaDataObject.Kontrollpunkter_Kommentar_10, "Har elektrikeren riktig verktøy til å gjennomføre oppdraget?")}
                                                {this.renderSjekkpunktTableRow(schemaDataObject.Kontrollpunkter11, schemaDataObject.Kontrollpunkter_Kommentar_11, "Har personale som skal utføre installasjonen riktig kompetanse?")}
                                                {this.renderSjekkpunktTableRow(schemaDataObject.Kontrollpunkter12, schemaDataObject.Kontrollpunkter_Kommentar_12, "Har du tilgang til test-/måleinstrumenter som er egnet til forutsatt bruk?")}
                                                {this.renderSjekkpunktTableRow(schemaDataObject.Kontrollpunkter13, schemaDataObject.Kontrollpunkter_Kommentar_13, "Dersom du kan bli nødt til å bestille en utkobling fra e-verket, har du tatt hensyn til alle punkter i PR-00-21?")}
                                                {this.renderSjekkpunktTableRow(schemaDataObject.Kontrollpunkter14, schemaDataObject.Kontrollpunkter_Kommentar_14, "Kan oppdraget utføres uten tilleggsprosjektering som krever dialog med hovedkontoret. Ref. SL-100-05 ?")}

                                            </tbody>
                                        </table>



                                        <br></br><br></br>

                                        {/* ---------------- FOOTER ----------------  */}

                                        <SchemaFooter Date={schemaDataObject.Date} Montor={schemaDataObject.Montor} FooterText={this.props.footerText} CompanyURL={this.props.companyUrl} />

                                    </CardBody>
                                </div>
                            </Card>
                        </Col>
                    </Row>

                </div>
            </>
        )

    }
}

export default SjekklisteRisiko;

import React from "react";
// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Table,
    Row,
    Col
} from "reactstrap";
import Loader from "components/Loader";
import SchemaFooter from "components/SchemaFooter";
import SchemaHeader from "components/SchemaHeader";
import { Base64 } from 'js-base64';


class Velkomstbrev extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            loading: true,
            schemaDataObject: {},
        };
    }

    componentDidMount() {

        //Load schema data
        fetch("https://rokeagruppenapi.azurewebsites.net/api/Customer/CustomerSchemaContent?jobId=" + this.props.jobId + "&schemaId=10", {
            method: "GET",
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + Base64.encode(sessionStorage.getItem("id") + ":" + sessionStorage.getItem("value")).replace(/['"]+/g, ''),
                'CompanyID': sessionStorage.getItem("companyID")
            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        loading: false,
                        schemaDataObject: result,
                    });
                },
                (error) => {
                    this.setState({
                        loading: false,
                        error: true
                    });
                }
            )
    }


    render() {

        const { error, loading, schemaDataObject } = this.state;
        if (loading) {
            return <Loader />
        }

        if (error) {
            return <h2>AUCH!!</h2>
        }

        return (
            <>
                <div className="content">

                    <Row>
                        <Col md="12">
                            <Card>
                                <SchemaHeader></SchemaHeader>
                                <div id="printDiv">

                                    {/* ---------------- KUNDEINFORMASJON ----------------  */}
                                    <CardHeader>
                                        <center>
                                            <img alt='logo' style={{ width: 200 + 'px' }} src={sessionStorage.getItem("companyLogoUrl")}></img>
                                        </center>
                                        <CardTitle tag="h4">Velkommen som kunde av {sessionStorage.getItem("company")}!</CardTitle>
                                        <CardTitle tag="b">Vi er klare til å gjøre jobben og ser fram til et godt samarbeid.</CardTitle>
                                    </CardHeader>

                                    <CardBody>
                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td><b>Kundeinformasjon:</b></td>
                                                </tr>
                                            </tbody>
                                        </Table>

                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td style={{ width: "30%", borderTop: 1 + "px", color: "black" }} ><b>Kunde:</b></td>
                                                    <td style={{ borderTop: 1 + "px" }} >{schemaDataObject.Kunde}</td>
                                                    <td style={{ width: "30%", borderTop: 1 + "px", color: "black" }} ><b>Jobb nummer:</b></td>
                                                    <td style={{ borderTop: 1 + "px" }} >{schemaDataObject.jobID}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: "30%", borderTop: 1 + "px", color: "black" }} ><b>Adresse:</b></td>
                                                    <td style={{ borderTop: 1 + "px" }} >{schemaDataObject.Adresse}</td>
                                                    <td style={{ width: "30%", borderTop: 1 + "px", color: "black" }} ><b>Telefonnummer:</b></td>
                                                    <td style={{ borderTop: 1 + "px" }} >{schemaDataObject.Tlfnr}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: "30%", borderTop: 1 + "px", color: "black" }} ><b>Postnummer/ Sted:</b></td>
                                                    <td style={{ borderTop: 1 + "px" }} >{schemaDataObject.PnrSted}</td>
                                                    <td style={{ width: "30%", borderTop: 1 + "px", color: "black" }} ><b>Epost:</b></td>
                                                    <td style={{ borderTop: 1 + "px" }} >{schemaDataObject.Email}</td>
                                                </tr>
                                            </tbody>
                                        </Table>


                                        {/* ---------------- HVOR MYE KOSTER DET ----------------  */}
                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td><b>Hvor mye koster det?</b></td>
                                                </tr>
                                            </tbody>
                                        </Table>

                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td style={{ width: "30%", borderTop: 1 + "px", color: "black" }} ><b>Standardarbeid:</b></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: "30%", borderTop: 1 + "px", color: "black" }} > - Oppstart, inkludert en times arbeid: 2350 kroner + mva.</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: "30%", borderTop: 1 + "px", color: "black" }} > - Timepris utover oppstartstimen: 749 kroner + mva.</td>
                                                </tr>
                                            </tbody>
                                        </Table>

                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td style={{ width: "30%", borderTop: 1 + "px", color: "black" }} ><b>Elektriker på dagen:</b></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: "30%", borderTop: 1 + "px", color: "black" }} > - Oppstart: 2350 kroner + mva.</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: "30%", borderTop: 1 + "px", color: "black" }} > - Timepris utover oppstartstimen: 999 kroner + mva.</td>
                                                </tr>
                                            </tbody>
                                        </Table>

                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td style={{ width: "30%", borderTop: 1 + "px", color: "black" }} ><b>Døgnvakt:</b></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: "30%", borderTop: 1 + "px", color: "black" }} > - Timepris: opp til 6000 kroner + mva.</td>
                                                </tr>
                                            </tbody>
                                        </Table>

                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td style={{ width: "30%", borderTop: 1 + "px", color: "black" }} ><b>-Priser for materiell kommer i tillegg.</b></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: "30%", borderTop: 1 + "px", color: "black" }} ><b>-Du betaler ikke for befaring eller eventuelt reklamasjonsarbeid</b></td>
                                                </tr>
                                            </tbody>
                                        </Table>

                                        {/* ---------------- HVORDAN BETALER DU ----------------  */}
                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td><b>Hvordan betaler du?</b></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: "30%", borderTop: 1 + "px", color: "black" }} > - Du betaler enkelt med kort når jobben er gjort, med mindre annen betalingsmåte er avtalt. Vi har med betalingsterminal.</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: "30%", borderTop: 1 + "px", color: "black" }} > - Elektrikeren oppretter et betalingsgrunnlag på stedet og går gjennom det sammen med deg før du betaler.</td>
                                                </tr>
                                            </tbody>
                                        </Table>

                                        {/* ---------------- BEHOLDE UTSTYR ----------------  */}
                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td><b>Vil du beholde demonterte deler eller utstyr?</b></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: "30%", borderTop: 1 + "px", color: "black" }} >Vi sørger for at avfallet blir forsvarlig håndtert. Elektrikeren vil ta med seg alt demontert utstyr og levere det som EE-avfall. Kryss av nedenfor om du ønsker å beholde demontert utstyr.</td>
                                                </tr>
                                            </tbody>
                                        </Table>

                                        {/* ---------------- SAMTYKKE ----------------  */}
                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td><b>Samtykke</b></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: "30%", borderTop: 1 + "px", color: "black" }} >
                                                        <input type="Checkbox" style={{ marginRight: 10 + "px" }} disabled checked={schemaDataObject.avtalenLest} ></input>
                                                    Jeg har lest og forstått punktene i denne avtalen og bekrefter å ha fullmakt til å kunne bestille endringer utført i elektroinstallasjonen på den aktuelle adressen.
                                                </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: "30%", borderTop: 1 + "px", color: "black" }} >
                                                        <input type="Checkbox" style={{ marginRight: 10 + "px" }} disabled checked={schemaDataObject.beholdeUtstyr} ></input>
                                                    Jeg ønsker å beholde demontert utstyr.
                                                </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: "30%", borderTop: 1 + "px", color: "black" }} >
                                                        Dato: {schemaDataObject.signDato}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: "30%", borderTop: 1 + "px", color: "black" }} >
                                                        Kunde signatur:
                                                </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: "30%", borderTop: 1 + "px", color: "black", paddingTop: 40 + "px" }} >
                                                        ------------------------------
                                                </td>
                                                </tr>
                                            </tbody>
                                        </Table>

                                        <br></br><br></br>

                                        {/* ---------------- FOOTER ----------------  */}
                                        <SchemaFooter Date={schemaDataObject.Date} Montor={schemaDataObject.Montor} FooterText={this.props.footerText} CompanyURL={this.props.companyUrl} />
                                    </CardBody>
                                </div>
                            </Card>
                        </Col>
                    </Row>

                </div>
            </>
        )

    }
}

export default Velkomstbrev;
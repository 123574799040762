
import React from "react";
// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Table,
    Row,
    Col
} from "reactstrap";
import Loader from "components/Loader";
import SchemaFooter from "components/SchemaFooter";
import SchemaHeader from "components/SchemaHeader";
import { Base64 } from 'js-base64';

class Kursfortegnelse extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            loading: true,
            schemaDataObject: {},
        };
    }

    componentDidMount() {

        //Load schema data
        fetch("https://rokeagruppenapi.azurewebsites.net/api/Customer/CustomerSchemaContent?jobId=" + this.props.jobId + "&schemaId=12", {
            method: "GET",
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + Base64.encode(sessionStorage.getItem("id") + ":" + sessionStorage.getItem("value")).replace(/['"]+/g, ''),
                'CompanyID': sessionStorage.getItem("companyID")
            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        loading: false,
                        schemaDataObject: result,
                    });
                },
                (error) => {
                    this.setState({
                        loading: false,
                        error: true
                    });
                }
            )
    }


    renderKursRows() {
        let table = []
        try {
            for (var i = 0; i < 30; i++) {
                let children = []
                let field = 'kurs' + i
                let row = Object.values(this.state.schemaDataObject[field])
                if (row[0] !== '') {
                    for (var y = 0; y < 9; y++) {
                        children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} >{row[y]}</td>)
                    }
                }
                table.push(<tr key={Math.random()}>{children}</tr>)
            }

            return table;

        } catch (e) {
            return table;
        }
    }

    render() {

        const checkBoxRowStyle = {
            paddingLeft: "0px",
            width: "30%",
            borderTop: "0px",

        };

        const tdColumn1Style = {
            width: "60%",
            borderTop: "0px",

        };

        const tdColumn2Style = {
            borderTop: "0px",
            padding: "4px",
            textAlign: "right",

        };


        const { error, loading, schemaDataObject } = this.state;
        if (loading) {
            return <Loader />
        }

        if (error) {
            return <h2>AUCH!!</h2>
        }

        return (
            <>
                <div className="content">

                    <Row>
                        <Col md="12">
                            <Card>

                                <SchemaHeader></SchemaHeader>

                                <div id="printDiv">
                                    {/* ---------------- ELEKTROINSTALLATØR ----------------  */}
                                    <CardHeader>
                                        <CardTitle tag="h4">Kursfortegnelse</CardTitle>
                                    </CardHeader>

                                    <CardBody>
                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td><b>ELEKTROINSTALLATØR</b></td>
                                                </tr>
                                            </tbody>
                                        </Table>

                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td style={tdColumn1Style} ><b>Firma:</b></td>
                                                    <td style={tdColumn2Style} >{schemaDataObject.Company[0]}</td>
                                                </tr>
                                                <tr>
                                                    <td style={tdColumn1Style} ><b>Adresse:</b></td>
                                                    <td style={tdColumn2Style} >{schemaDataObject.Company[1]}, {schemaDataObject.Company[2]}</td>
                                                </tr>
                                                <tr>
                                                    <td style={tdColumn1Style} ><b>Telefon:</b></td>
                                                    <td style={tdColumn2Style} >{schemaDataObject.Company[3]}</td>
                                                </tr>
                                            </tbody>
                                        </Table>

                                        {/* ---------------- INSTALLASJON ----------------  */}
                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td><b>INSTALLASJON</b></td>
                                                </tr>
                                            </tbody>
                                        </Table>

                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td style={tdColumn1Style} ><b>Anleggsnummer:</b></td>
                                                    <td style={tdColumn2Style} >{schemaDataObject.Installasjon[0]}</td>
                                                </tr>
                                                <tr>
                                                    <td style={tdColumn1Style} ><b>Målenummer:</b></td>
                                                    <td style={tdColumn2Style} >{schemaDataObject.Installasjon[1]}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ borderTop: 0 + "px" }} >
                                                        <b>Viktig: <i>Eier/bruker er ansvarlig for at den elektriske installasjonen og det elektriske utstyret er i henhold til gjeldende regelverk.</i></b>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>

                                        {/* ---------------- ANLEGGSADRESSE ----------------  */}
                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td><b>ANLEGGSADRESSE</b></td>
                                                </tr>
                                            </tbody>
                                        </Table>

                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td style={tdColumn1Style} ><b>Firma/ Navn:</b></td>
                                                    <td style={tdColumn2Style} >{schemaDataObject.Address[0]}</td>
                                                </tr>
                                                <tr>
                                                    <td style={tdColumn1Style} ><b>Adresse:</b></td>
                                                    <td style={tdColumn2Style} >{schemaDataObject.Address[1]}, {schemaDataObject.Address[2]} </td>
                                                </tr>
                                                <tr>
                                                    <td style={tdColumn1Style} ><b>Kundenummer:</b></td>
                                                    <td style={tdColumn2Style} >{schemaDataObject.Fgr[0]} </td>
                                                </tr>
                                                <tr>
                                                    <td style={tdColumn1Style} ><b>Ordrenummer:</b></td>
                                                    <td style={tdColumn2Style} >{schemaDataObject.Fgr[1]} </td>
                                                </tr>

                                            </tbody>
                                        </Table>

                                        {/* ---------------- KORTSLUTNINGSVERDIER ----------------  */}
                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td><b>KORTSLUTNINGSVERDIER</b></td>
                                                </tr>
                                            </tbody>
                                        </Table>

                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td style={tdColumn1Style} ><b>Fordeling:</b></td>
                                                    <td style={tdColumn2Style} >{schemaDataObject.Kortslutning[0]}</td>
                                                </tr>
                                                <tr>
                                                    <td style={tdColumn1Style} ><b>Ik Maks [kA]</b></td>
                                                    <td style={tdColumn2Style} >{schemaDataObject.Kortslutning[1]} </td>
                                                </tr>
                                                <tr>
                                                    <td style={tdColumn1Style} ><b>Ik Min [kA]</b></td>
                                                    <td style={tdColumn2Style} >{schemaDataObject.Kortslutning[2]} </td>
                                                </tr>
                                                <tr>
                                                    <td style={tdColumn1Style} ><b>Ij Min [kA] (kun ved TN anl.)</b></td>
                                                    <td style={tdColumn2Style} >{schemaDataObject.Kortslutning[3]} </td>
                                                </tr>
                                            </tbody>
                                        </Table>

                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td style={checkBoxRowStyle} ><input type="Checkbox" style={{ marginRight: "10px" }} disabled checked={(() => {
                                                        if (this.state.schemaDataObject.KortslutningRadio === '1') { return true }
                                                    }
                                                    )()}></input>Målt</td>
                                                </tr>
                                                <tr>
                                                    <td style={checkBoxRowStyle} ><input type="Checkbox" style={{ marginRight: "10px" }} disabled checked={(() => {
                                                        if (this.state.schemaDataObject.KortslutningRadio === '2') { return true }
                                                    }
                                                    )()}></input>Oppgitt</td>
                                                </tr>
                                                <tr>
                                                    <td style={checkBoxRowStyle} ><input type="Checkbox" style={{ marginRight: "10px" }} disabled checked={(() => {
                                                        if (this.state.schemaDataObject.KortslutningRadio === '3') { return true }
                                                    }
                                                    )()}></input>Beregnet</td>
                                                </tr>
                                            </tbody>
                                        </Table>

                                        {/* ---------------- ANLEGGSDATA ----------------  */}
                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td><b>ANLEGGSDATA</b></td>
                                                </tr>
                                            </tbody>
                                        </Table>

                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td style={tdColumn1Style} ><b>Systemspenning V:</b></td>
                                                    <td style={tdColumn2Style} >{schemaDataObject.Anlegg[0]}</td>
                                                </tr>
                                                <tr>
                                                    <td style={tdColumn1Style} ><b>Fordelingssystem:</b></td>
                                                    <td style={tdColumn2Style} >{schemaDataObject.Anlegg[1]} </td>
                                                </tr>
                                                <tr>
                                                    <td style={tdColumn1Style} ><b>Type jordelektrode</b></td>
                                                    <td style={tdColumn2Style} >{schemaDataObject.Anlegg[2]} </td>
                                                </tr>
                                                <tr>
                                                    <td style={tdColumn1Style} ><b>Tilkoblingssted</b></td>
                                                    <td style={tdColumn2Style} >{schemaDataObject.Anlegg[3]} </td>
                                                </tr>
                                                <tr>
                                                    <td style={tdColumn1Style} ><b>Tilførsel [mm2]</b></td>
                                                    <td style={tdColumn2Style} >{schemaDataObject.Anlegg[3]} </td>
                                                </tr>
                                            </tbody>
                                        </Table>

                                        {/* ---------------- ANLEGGSDATA ----------------  */}
                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td><b>KURSER</b></td>
                                                </tr>
                                            </tbody>
                                        </Table>

                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} ><b>Kurs nr.:</b></td>
                                                    <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} ><b>Lastbeskrivelse/ utstyr.:</b></td>
                                                    <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} colSpan="3"><b>Vern:</b></td>
                                                    <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} colSpan="3"><b>Kabel:</b></td>
                                                    <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} ><b>JFB:</b></td>

                                                </tr>
                                                <tr>
                                                    <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} ></td>
                                                    <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} ></td>
                                                    <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} ><b>Type</b></td>
                                                    <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} ><b>In [a]:</b></td>
                                                    <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} ><b>kar:</b></td>
                                                    <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} ><b>S [mm2]</b></td>
                                                    <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} ><b>L [m]:</b></td>
                                                    <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} ><b>Ref. inst. met.</b></td>
                                                    <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} ><b>[mA]</b></td>
                                                </tr>

                                                {this.renderKursRows()}
                                            </tbody>
                                        </Table>

                                        <br></br><br></br>

                                        {/* ---------------- FOOTER ----------------  */}

                                        <SchemaFooter Date={schemaDataObject.Date} Montor={schemaDataObject.Montor} FooterText={this.props.footerText} CompanyURL={this.props.companyUrl} />

                                    </CardBody>
                                </div>
                            </Card>
                        </Col>
                    </Row>

                </div>
            </>
        )

    }
}

export default Kursfortegnelse;

import React from "react";
// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Table,
    Row,
    Col
} from "reactstrap";
import Loader from "components/Loader";
import SchemaFooter from "components/SchemaFooter";
import SchemaHeader from "components/SchemaHeader";
import { Base64 } from 'js-base64';



class Varmekabel extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            loading: true,
            schemaDataObject: {},
            avvikCol1Array: [],
            avvikCol2Array: [],
            tiltakArray: [],
        };
    }

    componentDidMount() {

        //Load schema data
        fetch("https://rokeagruppenapi.azurewebsites.net/api/Customer/CustomerSchemaContent?jobId=" + this.props.jobId + "&schemaId=6", {
            method: "GET",
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + Base64.encode(sessionStorage.getItem("id") + ":" + sessionStorage.getItem("value")).replace(/['"]+/g, ''),
                'CompanyID': sessionStorage.getItem("companyID")
            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    console.log(result)
                    this.setState({
                        loading: false,
                        schemaDataObject: result,
                        avvikCol1Array: result.textarea01,
                        avvikCol2Array: result.textarea02,
                        avvikCol6Array: result.text1,
                        tiltak1Array: result.textarea03,
                        tiltak2Array: result.textarea04,
                    });
                },
                (error) => {
                    this.setState({
                        loading: false,
                        error: true
                    });
                }
            )
    }


    checkSomething = () => {

    }

    render() {

        const tdColumn1Style = {
            width: "60%",
            borderTop: "0px",

        };

        const tdColumn2Style = {
            borderTop: "0px",
            padding: "4px",
            textAlign: "right",

        };


        const { error, loading, schemaDataObject } = this.state;
        if (loading) {
            return <Loader />
        }

        if (error) {
            return <h2>AUCH!!</h2>
        }

        return (
            <>
                <div className="content">

                    <Row>
                        <Col md="12">
                            <Card>
                                <SchemaHeader></SchemaHeader>
                                <div id="printDiv">

                                    <CardHeader>
                                        <CardTitle tag="h4">Varmekabel</CardTitle>
                                        <CardTitle tag="b">Installert av {this.props.footerText}</CardTitle>
                                    </CardHeader>

                                    <CardBody>
                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td style={{ width: "60%" }}><b>Installasjonsadresse:</b></td>
                                                    <td style={{ textAlign: "right" }}>{schemaDataObject.FullJobAddress}</td>
                                                </tr>
                                                <tr>
                                                    <td style={tdColumn1Style} ><b>Antall rom:</b></td>
                                                    <td style={tdColumn2Style} >{schemaDataObject.textfield5}</td>

                                                </tr>
                                                <tr>
                                                    <td style={tdColumn1Style} ><b>Areal:</b></td>
                                                    <td style={tdColumn2Style} >{schemaDataObject.textfield6} m2</td>
                                                </tr>
                                            </tbody>
                                        </Table>


                                        {/* ---------------- MERKEVERDIER ----------------  */}
                                        <CardHeader>
                                            <CardTitle><b>Merkeverdier</b></CardTitle>
                                        </CardHeader>
                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td style={{ width: "60%" }} ><b>Typebetegnelse:</b></td>
                                                    <td style={{ textAlign: "right" }}>{schemaDataObject.textfield2}</td>
                                                </tr>
                                                <tr>
                                                    <td style={tdColumn1Style} ><b>Enleder eller toleder:</b></td>
                                                    <td style={tdColumn2Style} >{(() => {
                                                        if (this.state.schemaDataObject.checkbox) { return "Enleder" }
                                                        else { return "Toleder" }
                                                    }
                                                    )()}</td>
                                                </tr>
                                                <tr>
                                                    <td style={tdColumn1Style} ><b>Metereffekt - 17W/m eller 10W/m:</b></td>
                                                    <td style={tdColumn2Style} >{(() => {
                                                        if (this.state.schemaDataObject.checkbox3) { return "17 W/m" }
                                                        else { return "10 W/m" }
                                                    }
                                                    )()}</td>
                                                </tr>
                                                <tr>
                                                    <td style={tdColumn1Style} ><b>Annet</b></td>
                                                    <td style={tdColumn2Style} >{schemaDataObject.textfield3}</td>
                                                </tr>
                                                <tr>
                                                    <td style={tdColumn1Style} ><b>Nominell motstand:</b></td>
                                                    <td style={tdColumn2Style} >{schemaDataObject.textfield4} Ohm</td>
                                                </tr>
                                                <tr>
                                                    <td style={tdColumn1Style} ><b>Driftsspenning</b></td>
                                                    <td style={tdColumn2Style} >{schemaDataObject.textfield8} V</td>
                                                </tr>

                                            </tbody>
                                        </Table>

                                        {/* ---------------- KONTROLLMÅLING FØR INNSTØPING ----------------  */}
                                        <CardHeader>
                                            <CardTitle><b>Kontrollmåling før innstøping</b></CardTitle>
                                        </CardHeader>
                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td style={{ width: "60%" }} ><b>Ledermotstand:</b></td>
                                                    <td style={{ textAlign: "right" }} >{schemaDataObject.textfield9} Ohm</td>
                                                </tr>
                                                <tr>
                                                    <td style={tdColumn1Style} ><b>Installasjonsmotstand mindre enn 500 Mohm:</b></td>
                                                    <td style={tdColumn2Style} >{(() => {
                                                        if (this.state.schemaDataObject.checkbox5) { return "Ja" }
                                                        else { return "Nei" }
                                                    }
                                                    )()}</td>
                                                </tr>
                                                <tr>
                                                    <td style={tdColumn1Style} ><b>Dato:</b></td>
                                                    <td style={tdColumn2Style} >{schemaDataObject.textfield10}</td>
                                                </tr>
                                                <tr>
                                                    <td style={tdColumn1Style} ><b>Underskrift:</b></td>
                                                    <td style={tdColumn2Style} >{schemaDataObject.textfield11}</td>
                                                </tr>
                                            </tbody>
                                        </Table>


                                        {/* ---------------- ANLEGGSDATA ----------------  */}
                                        <CardHeader>
                                            <CardTitle><b>Anleggsdata</b></CardTitle>
                                        </CardHeader>
                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td style={{ width: "60%" }} ><b>Montasjedybde:</b></td>
                                                    <td style={{ textAlign: "right" }} >{schemaDataObject.textfield13} cm</td>
                                                </tr>
                                                <tr>
                                                    <td style={tdColumn1Style} ><b>Antall elementer installert</b></td>
                                                    <td style={tdColumn2Style} >{schemaDataObject.textfield14} stk.</td>
                                                </tr>
                                                <tr>
                                                    <td style={tdColumn1Style} ><b>Oppvarmet areal:</b></td>
                                                    <td style={tdColumn2Style} >{schemaDataObject.textfield15} m2</td>
                                                </tr>
                                                <tr>
                                                    <td style={tdColumn1Style} ><b>Flateeffekt:</b></td>
                                                    <td style={tdColumn2Style} >{schemaDataObject.textfield16} W/m2</td>
                                                </tr>
                                                <tr>
                                                    <td style={tdColumn1Style} ><b>Sikringsstørrelse:</b></td>
                                                    <td style={tdColumn2Style} >{schemaDataObject.textfield17} A</td>
                                                </tr>
                                                <tr>
                                                    <td style={tdColumn1Style} ><b>Utløsestrøm for jordfeilvern:</b></td>
                                                    <td style={tdColumn2Style} > Mindre enn 30 mA</td>
                                                </tr>
                                                <tr>
                                                    <td style={tdColumn1Style} ><b>Jordet kabelskjerm</b></td>
                                                    <td style={tdColumn2Style} >{(() => {
                                                        if (this.state.schemaDataObject.checkbox6) { return "Ja" }
                                                        else { return "Nei" }
                                                    }
                                                    )()}</td>
                                                </tr>
                                                <tr>
                                                    <td style={tdColumn1Style} ><b>Jordet netting</b></td>
                                                    <td style={tdColumn2Style} >{(() => {
                                                        if (this.state.schemaDataObject.checkbox7) { return "Ja" }
                                                        else { return "Nei" }
                                                    }
                                                    )()}</td>
                                                </tr>
                                                <tr>
                                                    <td style={tdColumn1Style} ><b>Annet:</b></td>
                                                    <td style={tdColumn2Style} >{schemaDataObject.textfield12}</td>
                                                </tr>
                                            </tbody>
                                        </Table>


                                        {/* ---------------- Maks temperatur i oppvarminssystemet er begrenset til 80°C ved: ----------------  */}
                                        <CardHeader>
                                            <CardTitle><b>Maks temperatur i oppvarminssystemet er begrenset til 80°C ved:</b></CardTitle>
                                        </CardHeader>
                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td style={{ width: "60%" }} ><b>Planlegging (Monteringsanvisning er fulgt, og eier er informert om forutsetningene):</b></td>
                                                    <td style={{ textAlign: "right" }} >{(() => {
                                                        if (this.state.schemaDataObject.checkbox9) { return "Ja" }
                                                        else { return "Nei" }
                                                    }
                                                    )()}</td>
                                                </tr>
                                                <tr>
                                                    <td style={tdColumn1Style} ><b>Montasjen (Monteringsanvisningen er fulgt)</b></td>
                                                    <td style={tdColumn2Style} >{(() => {
                                                        if (this.state.schemaDataObject.checkbox10) { return "Ja" }
                                                        else { return "Nei" }
                                                    }
                                                    )()}</td>
                                                </tr>
                                                <tr>
                                                    <td style={tdColumn1Style} ><b>Bruk av beskyttelsesutstyr (beskrives under)</b></td>
                                                    <td style={tdColumn2Style} >{(() => {
                                                        if (this.state.schemaDataObject.checkbox11) { return "Ja" }
                                                        else { return "Nei" }
                                                    }
                                                    )()}</td>
                                                </tr>
                                                <tr>
                                                    <td style={tdColumn1Style} >{schemaDataObject.textarea}</td>
                                                </tr>
                                            </tbody>
                                        </Table>

                                        {/* ---------------- Styrings- /reguleringsutrustningen ----------------  */}
                                        <CardHeader>
                                            <CardTitle><b>Styrings- og reguleringsutrustning</b></CardTitle>
                                        </CardHeader>
                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td style={{ width: "60%" }} ><b>Type betegnelse:</b></td>
                                                    <td style={{ textAlign: "right" }} >{schemaDataObject.textfield18}</td>
                                                </tr>
                                                <tr>
                                                    <td style={tdColumn1Style} ><b>Gulvføler</b></td>
                                                    <td style={tdColumn2Style} >{(() => {
                                                        if (this.state.schemaDataObject.checkbox12) { return "Ja" }
                                                        else { return "Nei" }
                                                    }
                                                    )()}</td>
                                                </tr>
                                                <tr>
                                                    <td style={tdColumn1Style} ><b>Romføler</b></td>
                                                    <td style={tdColumn2Style} >{(() => {
                                                        if (this.state.schemaDataObject.checkbox13) { return "Ja" }
                                                        else { return "Nei" }
                                                    }
                                                    )()}</td>
                                                </tr>
                                                <tr>
                                                    <td style={tdColumn1Style} ><b>Annen type</b></td>
                                                    <td style={tdColumn2Style} >{schemaDataObject.textfield19}</td>
                                                </tr>
                                            </tbody>
                                        </Table>

                                        {/* ---------------- Kontrollmåling umiddelbart etter innstøping ----------------  */}
                                        <CardHeader>
                                            <CardTitle><b>Kontrollmåling umiddelbart etter innstøping</b></CardTitle>
                                        </CardHeader>
                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td style={{ width: "60%" }} ><b>Ledermotstand:</b></td>
                                                    <td style={{ textAlign: "right" }} >{schemaDataObject.textfield22} Ohm</td>
                                                </tr>
                                                <tr>
                                                    <td style={tdColumn1Style} ><b>Isolasjonsmotstand er mindre enn 500 Mohm?</b></td>
                                                    <td style={tdColumn2Style} >{(() => {
                                                        if (this.state.schemaDataObject.checkbox15) { return "Ja" }
                                                        else { return "Nei" }
                                                    }
                                                    )()}</td>
                                                </tr>
                                                <tr>
                                                    <td style={tdColumn1Style} ><b>Dato:</b></td>
                                                    <td style={tdColumn2Style} >{schemaDataObject.textfield20}</td>
                                                </tr>
                                                <tr>
                                                    <td style={tdColumn1Style} ><b>Underskrift:</b></td>
                                                    <td style={tdColumn2Style}  >{schemaDataObject.textfield21}</td>
                                                </tr>
                                            </tbody>
                                        </Table>


                                        {/* ---------------- Kontrollmåling ved tilkobling/oppstart ----------------  */}
                                        <CardHeader>
                                            <CardTitle><b>Kontrollmåling ved tilkobling/oppstart</b></CardTitle>
                                        </CardHeader>
                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td style={{ width: "60%" }} ><b>Ledermotstand:</b></td>
                                                    <td style={{ textAlign: "right" }} >{schemaDataObject.textfield23} Ohm</td>
                                                </tr>
                                                <tr>
                                                    <td style={tdColumn1Style} ><b>Isolasjonsmotstand er mindre enn 500 Mohm?</b></td>
                                                    <td style={tdColumn2Style} >{(() => {
                                                        if (this.state.schemaDataObject.checkbox16) { return "Ja" }
                                                        else { return "Nei" }
                                                    }
                                                    )()}</td>
                                                </tr>
                                                <tr>
                                                    <td style={tdColumn1Style} ><b>Dato:</b></td>
                                                    <td style={tdColumn2Style} >{schemaDataObject.textfield24}</td>
                                                </tr>
                                                <tr>
                                                    <td style={tdColumn1Style} ><b>Underskrift:</b></td>
                                                    <td style={{ borderTop: 0 + 'px' }} >{schemaDataObject.textfield25}</td>
                                                </tr>
                                            </tbody>
                                        </Table>

                                        {/* ---------------- Installatør ----------------  */}
                                        <CardHeader>
                                            <CardTitle><b>Installatør</b></CardTitle>
                                        </CardHeader>
                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td style={{ width: 60 + '%' }} ><b>Varmekabelanlegget er montert i henhold til Nexans Norway's monteringsanvisning, og anleggseier er opplyst om hvilke forhåndsregler som gjelder for bruk av gulvvarmesystemet:</b></td>
                                                    <td style={{ textAlign: 'right' }} >{(() => {
                                                        if (this.state.schemaDataObject.checkbox18) { return "Ja" }
                                                        else { return "Nei" }
                                                    }
                                                    )()}</td>
                                                </tr>
                                                <tr>
                                                    <td style={tdColumn1Style}  ><b>Dato:</b></td>
                                                    <td style={tdColumn2Style}  >{schemaDataObject.Date}</td>
                                                </tr>
                                                <tr>
                                                    <td style={tdColumn1Style} ><b>Underskrift:</b></td>
                                                    <td style={tdColumn2Style} >{schemaDataObject.textfield27}</td>
                                                </tr>
                                            </tbody>
                                        </Table>

                                        <br></br><br></br>

                                        {/* ---------------- FOOTER ----------------  */}

                                        <SchemaFooter Date={schemaDataObject.Date} Montor={schemaDataObject.Montor} FooterText={this.props.footerText} CompanyURL={this.props.companyUrl} />


                                    </CardBody>
                                </div>
                            </Card>
                        </Col>
                    </Row>

                </div>
            </>
        )

    }
}

export default Varmekabel;

import React from "react";
// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Table,
    Row,
    Col
} from "reactstrap";
import Loader from "components/Loader";
import SchemaFooter from "components/SchemaFooter";
import SchemaHeader from "components/SchemaHeader";
import { Base64 } from 'js-base64';


class ForenkletSluttkontroll extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            loading: true,
            schemaDataObject: {},
        };
    }

    componentDidMount() {

        //Load schema data
        fetch("https://rokeagruppenapi.azurewebsites.net/api/Customer/CustomerSchemaContent?jobId=" + this.props.jobId + "&schemaId=13", {
            method: "GET",
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + Base64.encode(sessionStorage.getItem("id") + ":" + sessionStorage.getItem("value")).replace(/['"]+/g, ''),
                'CompanyID': sessionStorage.getItem("companyID")
            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    console.log(result)
                    this.setState({
                        loading: false,
                        schemaDataObject: result,
                    });
                },
                (error) => {
                    this.setState({
                        loading: false,
                        error: true
                    });
                }
            )
    }


    render() {

        const { error, loading, schemaDataObject } = this.state;
        if (loading) {
            return <Loader />
        }

        if (error) {
            return <h2>AUCH!!</h2>
        }

        return (
            <>
                <div className="content">

                    <Row>
                        <Col md="12">
                            <Card>
                                <SchemaHeader></SchemaHeader>
                                <div id="printDiv">

                                    {/* ---------------- KUNDEINFORMASJON ----------------  */}
                                    <CardHeader>
                                        <CardTitle tag="h4">Dokumentasjon av serviceoppdrag med lav risiko</CardTitle>
                                    </CardHeader>

                                    <CardBody>
                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td><b>Kundeinformasjon:</b></td>
                                                </tr>
                                            </tbody>
                                        </Table>

                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td style={{ width: "30%", borderTop: 1 + "px", color: "black" }} ><b>Kunde:</b></td>
                                                    <td style={{ borderTop: 1 + "px" }} >{schemaDataObject.Kunde}</td>
                                                    <td style={{ width: "30%", borderTop: 1 + "px", color: "black" }} ><b>Jobb nummer:</b></td>
                                                    <td style={{ borderTop: 1 + "px" }} >{schemaDataObject.jobID}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: "30%", borderTop: 1 + "px", color: "black" }} ><b>Adresse:</b></td>
                                                    <td style={{ borderTop: 1 + "px" }} >{schemaDataObject.Adresse}</td>
                                                    <td style={{ width: "30%", borderTop: 1 + "px", color: "black" }} ><b>Telefonnummer:</b></td>
                                                    <td style={{ borderTop: 1 + "px" }} >{schemaDataObject.Tlfnr}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: "30%", borderTop: 1 + "px", color: "black" }} ><b>Postnummer/ Sted:</b></td>
                                                    <td style={{ borderTop: 1 + "px" }} >{schemaDataObject.PnrSted}</td>
                                                    <td style={{ width: "30%", borderTop: 1 + "px", color: "black" }} ><b>Epost:</b></td>
                                                    <td style={{ borderTop: 1 + "px" }} >{schemaDataObject.Email}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                        
                                        <br></br>
                                          {/* ---------------- VIKTIG ----------------  */}
                                          <Table>
                                            <tbody>
                                                <tr>
                                                    <td style={{ borderTop: 0 + "px" }}><b>Viktig</b></td>
                                                </tr>
                                                <tr>
                                                    <td><p style={{color: "red" }} >Denne sjekklisten skal kun benyttes til enkle installasjonsoppdrag med lav risiko. Dersom det kan svares JA pà ALLE sjekkpunkt i gruppen "Risikovurdering (Forutsetninger- lav risiko)" er arbeidet å anse som "mindre oppdrag med lav risiko" og sjekklisten kan benyttes. Dersom ikke, må komplett 5 Sikre dokumentasjon benyttes. Det er en forutsetning at utførende kommuniserer med eier/bruker. Forenklet dokumentasjon kan ikke benyttes for installasjoner som omfattes av NEK400 del 7</p></td>
                                                </tr>
                                            </tbody>
                                        </Table>

                                        {/* ---------------- RAPPORTENS OMFANG ----------------  */}
                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td style={{ borderTop: 0 + "px" }}><b>Rapportens omfang</b></td>
                                                </tr>
                                            </tbody>
                                        </Table>

                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td style={{ width: "30%", borderTop: 0 + "px"}} >Hele anlegget</td>
                                                    <td key={Math.random()} style={{ border: 0 + "px solid black", padding: 8 + "px" }}>
                                                        <input type="Checkbox" style={{ marginRight: "10px" }} disabled checked={(() => {
                                                            try { if (this.state.schemaDataObject.omfang === "omfang_part") { return false } }
                                                            catch{ }
                                                        }
                                                        )()}></input></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: "30%", borderTop: 0 + "px"}} >Anleggsdel</td>
                                                    <td key={Math.random()} style={{ border: 0 + "px solid black", padding: 8 + "px" }}>
                                                        <input type="Checkbox" style={{ marginRight: "10px" }} disabled checked={(() => {
                                                            try { if (this.state.schemaDataObject.omfang === "omfang_part") { return true } }
                                                            catch{ }
                                                        }
                                                        )()}></input></td>
                                                </tr>
                                                
                                            </tbody>
                                        </Table>

                                          {/* ---------------- RISIKOVURDERING (FORUTSETNINGER FOR LAV RISIKO) ----------------  */}
                                          <Table>
                                            <tbody>
                                                <tr>
                                                    <td  style={{ borderTop: 0 + "px" }}><b>Risikovurdering - Forutsetninger for lav risiko</b></td>
                                                </tr>
                                            </tbody>
                                        </Table>

                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td style={{ borderTop: 0 + "px" }}>SJEKKPUNKT</td>
                                                    <td style={{ borderTop: 0 + "px" }}>JA</td>
                                                    <td style={{ borderTop: 0 + "px" }}>NEI</td>
                                                    <td style={{ borderTop: 0 + "px" }}>IKKE AKTUELT</td>
                                                    <td style={{ borderTop: 0 + "px" }}>KOMMENTAR</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ borderTop: 0 + "px" }}>Foreligger det tilstrekkelig informasjon til å kunne vurdere?</td>
                                                    <td key={Math.random()} style={{ border: 0 + "px", padding: 8 + "px" }}>
                                                        <input type="Checkbox" style={{ marginRight: "10px" }} disabled checked={(() => {
                                                            try { if (this.state.schemaDataObject.Kontrollpunkter0.includes("_Ok_")) { return true } }
                                                            catch{ }
                                                        }
                                                        )()}></input>
                                                    </td>
                                                    
                                                    <td key={Math.random()} style={{ border: 0 + "px", padding: 8 + "px" }}>
                                                        <input type="Checkbox" style={{ marginRight: "10px" }} disabled checked={(() => {
                                                            try { if (this.state.schemaDataObject.Kontrollpunkter0.includes("_Nok_")) { return true } }
                                                            catch{ }
                                                        }
                                                        )()}></input>
                                                    </td>
                                                    <td key={Math.random()} style={{ border: 0 + "px", padding: 8 + "px" }}>
                                                        <input type="Checkbox" style={{ marginRight: "10px" }} disabled checked={(() => {
                                                            try { if (this.state.schemaDataObject.Kontrollpunkter0.includes("_Na_")) { return true } }
                                                            catch{ }
                                                        }
                                                        )()}></input>
                                                    </td>
                                                    <td style={{ borderTop: 0 + "px" }}>
                                                        {this.state.schemaDataObject.Kontrollpunkter_Kommentar_0}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ borderTop: 0 + "px" }}>Er ytre påvirkninger å anse som normale i forhold til NEK 400?</td>
                                                    <td key={Math.random()} style={{ border: 0 + "px", padding: 8 + "px" }}>
                                                        <input type="Checkbox" style={{ marginRight: "10px" }} disabled checked={(() => {
                                                            try { if (this.state.schemaDataObject.Kontrollpunkter1.includes("_Ok_")) { return true } }
                                                            catch{ }
                                                        }
                                                        )()}></input>
                                                    </td>
                                                    <td key={Math.random()} style={{ border: 0 + "px", padding: 8 + "px" }}>
                                                        <input type="Checkbox" style={{ marginRight: "10px" }} disabled checked={(() => {
                                                            try { if (this.state.schemaDataObject.Kontrollpunkter1.includes("_Nok_")) { return true } }
                                                            catch{ }
                                                        }
                                                        )()}></input>
                                                    </td>
                                                    <td key={Math.random()} style={{ border: 0 + "px", padding: 8 + "px" }}>
                                                        <input type="Checkbox" style={{ marginRight: "10px" }} disabled checked={(() => {
                                                            try { if (this.state.schemaDataObject.Kontrollpunkter1.includes("_Na_")) { return true } }
                                                            catch{ }
                                                        }
                                                        )()}></input>
                                                    </td>
                                                    <td style={{ borderTop: 0 + "px" }}>
                                                        {this.state.schemaDataObject.Kontrollpunkter_Kommentar_1}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ borderTop: 0 + "px" }}>Foreligger det tilstrekkelig informasjon om løsningene som er som er lagt till grunn i eksisterende anlegg?</td>
                                                    <td key={Math.random()} style={{ border: 0 + "px", padding: 8 + "px" }}>
                                                        <input type="Checkbox" style={{ marginRight: "10px" }} disabled checked={(() => {
                                                            try { if (this.state.schemaDataObject.Kontrollpunkter2.includes("_Ok_")) { return true } }
                                                            catch{ }
                                                        }
                                                        )()}></input>
                                                    </td>
                                                    <td key={Math.random()} style={{ border: 0 + "px", padding: 8 + "px" }}>
                                                        <input type="Checkbox" style={{ marginRight: "10px" }} disabled checked={(() => {
                                                            try { if (this.state.schemaDataObject.Kontrollpunkter2.includes("_Nok_")) { return true } }
                                                            catch{ }
                                                        }
                                                        )()}></input>
                                                    </td>
                                                    <td key={Math.random()} style={{ border: 0 + "px", padding: 8 + "px" }}>
                                                        <input type="Checkbox" style={{ marginRight: "10px" }} disabled checked={(() => {
                                                            try { if (this.state.schemaDataObject.Kontrollpunkter2.includes("_Na_")) { return true } }
                                                            catch{ }
                                                        }
                                                        )()}></input>
                                                    </td>
                                                    <td style={{ borderTop: 0 + "px" }}>
                                                        {this.state.schemaDataObject.Kontrollpunkter_Kommentar_2}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ borderTop: 0 + "px" }}>Er det åpenbart at arbeidet ikke vil påvirke forutsetningene?</td>
                                                    <td key={Math.random()} style={{ border: 0 + "px", padding: 8 + "px" }}>
                                                        <input type="Checkbox" style={{ marginRight: "10px" }} disabled checked={(() => {
                                                            try { if (this.state.schemaDataObject.Kontrollpunkter3.includes("_Ok_")) { return true } }
                                                            catch{ }
                                                        }
                                                        )()}></input>
                                                    </td>
                                                    <td key={Math.random()} style={{ border: 0 + "px", padding: 8 + "px" }}>
                                                        <input type="Checkbox" style={{ marginRight: "10px" }} disabled checked={(() => {
                                                            try { if (this.state.schemaDataObject.Kontrollpunkter3.includes("_Nok_")) { return true } }
                                                            catch{ }
                                                        }
                                                        )()}></input>
                                                    </td>
                                                    <td key={Math.random()} style={{ border: 0 + "px", padding: 8 + "px" }}>
                                                        <input type="Checkbox" style={{ marginRight: "10px" }} disabled checked={(() => {
                                                            try { if (this.state.schemaDataObject.Kontrollpunkter3.includes("_Na_")) { return true } }
                                                            catch{ }
                                                        }
                                                        )()}></input>
                                                    </td>
                                                    <td style={{ borderTop: 0 + "px" }}>
                                                        {this.state.schemaDataObject.Kontrollpunkter_Kommentar_3}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ borderTop: 0 + "px" }}>Er det åpenbart at endringer, utvidelser ikke vil kunne kategoriseres under del 7 i NEK 400?</td>
                                                    <td key={Math.random()} style={{ border: 0 + "px", padding: 8 + "px" }}>
                                                        <input type="Checkbox" style={{ marginRight: "10px" }} disabled checked={(() => {
                                                            try { if (this.state.schemaDataObject.Kontrollpunkter4.includes("_Ok_")) { return true } }
                                                            catch{ }
                                                        }
                                                        )()}></input>
                                                    </td>
                                                    <td key={Math.random()} style={{ border: 0 + "px", padding: 8 + "px" }}>
                                                        <input type="Checkbox" style={{ marginRight: "10px" }} disabled checked={(() => {
                                                            try { if (this.state.schemaDataObject.Kontrollpunkter4.includes("_Nok_")) { return true } }
                                                            catch{ }
                                                        }
                                                        )()}></input>
                                                    </td>
                                                    <td key={Math.random()} style={{ border: 0 + "px", padding: 8 + "px" }}>
                                                        <input type="Checkbox" style={{ marginRight: "10px" }} disabled checked={(() => {
                                                            try { if (this.state.schemaDataObject.Kontrollpunkter4.includes("_Na_")) { return true } }
                                                            catch{ }
                                                        }
                                                        )()}></input>
                                                    </td>
                                                    <td style={{ borderTop: 0 + "px" }}>
                                                        {this.state.schemaDataObject.Kontrollpunkter_Kommentar_4}
                                                    </td>
                                                </tr><tr>
                                                    <td style={{ borderTop: 0 + "px" }}>Er utstyr valgt i forhold til bruk og temperatur?</td>
                                                    <td key={Math.random()} style={{ border: 0 + "px", padding: 8 + "px" }}>
                                                        <input type="Checkbox" style={{ marginRight: "10px" }} disabled checked={(() => {
                                                            try { if (this.state.schemaDataObject.Kontrollpunkter5.includes("_Ok_")) { return true } }
                                                            catch{ }
                                                        }
                                                        )()}></input>
                                                    </td>
                                                    <td key={Math.random()} style={{ border: 0 + "px", padding: 8 + "px" }}>
                                                        <input type="Checkbox" style={{ marginRight: "10px" }} disabled checked={(() => {
                                                            try { if (this.state.schemaDataObject.Kontrollpunkter5.includes("_Nok_")) { return true } }
                                                            catch{ }
                                                        }
                                                        )()}></input>
                                                    </td>
                                                    <td key={Math.random()} style={{ border: 0 + "px", padding: 8 + "px" }}>
                                                        <input type="Checkbox" style={{ marginRight: "10px" }} disabled checked={(() => {
                                                            try { if (this.state.schemaDataObject.Kontrollpunkter5.includes("_Na_")) { return true } }
                                                            catch{ }
                                                        }
                                                        )()}></input>
                                                    </td>
                                                    <td style={{ borderTop: 0 + "px" }}>
                                                        {this.state.schemaDataObject.Kontrollpunkter_Kommentar_5}
                                                    </td>
                                                </tr>
                                                
                                            </tbody>
                                        </Table>

                                         {/* ---------------- SLUTTKONTROLL (FORUTSETNINGER FOR LAV RISIKO) ----------------  */}
                                         <Table>
                                            <tbody>
                                                <tr>
                                                    <td  style={{ borderTop: 0 + "px" }}><b>Risikovurdering - Forutsetninger for lav risiko</b></td>
                                                </tr>
                                            </tbody>
                                        </Table>

                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td style={{ borderTop: 0 + "px" }}>SJEKKPUNKT</td>
                                                    <td style={{ borderTop: 0 + "px" }}>JA</td>
                                                    <td style={{ borderTop: 0 + "px" }}>NEI</td>
                                                    <td style={{ borderTop: 0 + "px" }}>IKKE AKTUELT</td>
                                                    <td style={{ borderTop: 0 + "px" }}>KOMMENTAR</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ borderTop: 0 + "px" }}>Er kontinuitet i beskyttelsesleder og utjevningsforbindelser malt og funnet i orden?</td>
                                                    <td key={Math.random()} style={{ border: 0 + "px", padding: 8 + "px" }}>
                                                        <input type="Checkbox" style={{ marginRight: "10px" }} disabled checked={(() => {
                                                            try { if (this.state.schemaDataObject.SluttKontrollpunkter0.includes("_Ok_")) { return true } }
                                                            catch{ }
                                                        }
                                                        )()}></input>
                                                    </td>
                                                    <td key={Math.random()} style={{ border: 0 + "px", padding: 8 + "px" }}>
                                                        <input type="Checkbox" style={{ marginRight: "10px" }} disabled checked={(() => {
                                                            try { if (this.state.schemaDataObject.SluttKontrollpunkter0.includes("_Nok_")) { return true } }
                                                            catch{ }
                                                        }
                                                        )()}></input>
                                                    </td>
                                                    <td key={Math.random()} style={{ border: 0 + "px", padding: 8 + "px" }}>
                                                        <input type="Checkbox" style={{ marginRight: "10px" }} disabled checked={(() => {
                                                            try { if (this.state.schemaDataObject.SluttKontrollpunkter0.includes("_Na_")) { return true } }
                                                            catch{ }
                                                        }
                                                        )()}></input>
                                                    </td>
                                                    <td style={{ borderTop: 0 + "px" }}>
                                                        {this.state.schemaDataObject.SluttKontrollpunkter_Kommentar_0}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ borderTop: 0 + "px" }}>Er isolasjonsmåling utført og funnet i orden??</td>
                                                    <td key={Math.random()} style={{ border: 0 + "px", padding: 8 + "px" }}>
                                                        <input type="Checkbox" style={{ marginRight: "10px" }} disabled checked={(() => {
                                                            try { if (this.state.schemaDataObject.SluttKontrollpunkter1.includes("_Ok_")) { return true } }
                                                            catch{ }
                                                        }
                                                        )()}></input>
                                                    </td>
                                                    <td key={Math.random()} style={{ border: 0 + "px", padding: 8 + "px" }}>
                                                        <input type="Checkbox" style={{ marginRight: "10px" }} disabled checked={(() => {
                                                            try { if (this.state.schemaDataObject.SluttKontrollpunkter1.includes("_Nok_")) { return true } }
                                                            catch{ }
                                                        }
                                                        )()}></input>
                                                    </td>
                                                    <td key={Math.random()} style={{ border: 0 + "px", padding: 8 + "px" }}>
                                                        <input type="Checkbox" style={{ marginRight: "10px" }} disabled checked={(() => {
                                                            try { if (this.state.schemaDataObject.SluttKontrollpunkter1.includes("_Na_")) { return true } }
                                                            catch{ }
                                                        }
                                                        )()}></input>
                                                    </td>
                                                    <td style={{ borderTop: 0 + "px" }}>
                                                        {this.state.schemaDataObject.SluttKontrollpunkter_Kommentar_1}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ borderTop: 0 + "px" }}>Er andre nødvendige målinger og prøving av den nye installasjonen utført og funnet i orden? Spesifiser nedenfor</td>
                                                    <td key={Math.random()} style={{ border: 0 + "px", padding: 8 + "px" }}>
                                                        <input type="Checkbox" style={{ marginRight: "10px" }} disabled checked={(() => {
                                                            try { if (this.state.schemaDataObject.SluttKontrollpunkter2.includes("_Ok_")) { return true } }
                                                            catch{ }
                                                        }
                                                        )()}></input>
                                                    </td>
                                                    <td key={Math.random()} style={{ border: 0 + "px", padding: 8 + "px" }}>
                                                        <input type="Checkbox" style={{ marginRight: "10px" }} disabled checked={(() => {
                                                            try { if (this.state.schemaDataObject.SluttKontrollpunkter2.includes("_Nok_")) { return true } }
                                                            catch{ }
                                                        }
                                                        )()}></input>
                                                    </td>
                                                    <td key={Math.random()} style={{ border: 0 + "px", padding: 8 + "px" }}>
                                                        <input type="Checkbox" style={{ marginRight: "10px" }} disabled checked={(() => {
                                                            try { if (this.state.schemaDataObject.SluttKontrollpunkter2.includes("_Na_")) { return true } }
                                                            catch{ }
                                                        }
                                                        )()}></input>
                                                    </td>
                                                    <td style={{ borderTop: 0 + "px" }}>
                                                        {this.state.schemaDataObject.SluttKontrollpunkter_Kommentar_2}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ borderTop: 0 + "px" }}>Er utført arbeid kontrollert og funnet i orden?</td>
                                                    <td key={Math.random()} style={{ border: 0 + "px", padding: 8 + "px" }}>
                                                        <input type="Checkbox" style={{ marginRight: "10px" }} disabled checked={(() => {
                                                            try { if (this.state.schemaDataObject.SluttKontrollpunkter3.includes("_Ok_")) { return true } }
                                                            catch{ }
                                                        }
                                                        )()}></input>
                                                    </td>
                                                    <td key={Math.random()} style={{ border: 0 + "px", padding: 8 + "px" }}>
                                                        <input type="Checkbox" style={{ marginRight: "10px" }} disabled checked={(() => {
                                                            try { if (this.state.schemaDataObject.SluttKontrollpunkter3.includes("_Nok_")) { return true } }
                                                            catch{ }
                                                        }
                                                        )()}></input>
                                                    </td>
                                                    <td key={Math.random()} style={{ border: 0 + "px", padding: 8 + "px" }}>
                                                        <input type="Checkbox" style={{ marginRight: "10px" }} disabled checked={(() => {
                                                            try { if (this.state.schemaDataObject.SluttKontrollpunkter3.includes("_Na_")) { return true } }
                                                            catch{ }
                                                        }
                                                        )()}></input>
                                                    </td>
                                                    <td style={{ borderTop: 0 + "px" }}>
                                                        {this.state.schemaDataObject.SluttKontrollpunkter_Kommentar_3}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ borderTop: 0 + "px" }}>Er benyttet utstyr tilpasset ytre påvvirkninger, spenningsnivå, og bruk?</td>
                                                    <td key={Math.random()} style={{ border: 0 + "px", padding: 8 + "px" }}>
                                                        <input type="Checkbox" style={{ marginRight: "10px" }} disabled checked={(() => {
                                                            try { if (this.state.schemaDataObject.SluttKontrollpunkter4.includes("_Ok_")) { return true } }
                                                            catch{ }
                                                        }
                                                        )()}></input>
                                                    </td>
                                                    <td key={Math.random()} style={{ border: 0 + "px", padding: 8 + "px" }}>
                                                        <input type="Checkbox" style={{ marginRight: "10px" }} disabled checked={(() => {
                                                            try { if (this.state.schemaDataObject.SluttKontrollpunkter4.includes("_Nok_")) { return true } }
                                                            catch{ }
                                                        }
                                                        )()}></input>
                                                    </td>
                                                    <td key={Math.random()} style={{ border: 0 + "px", padding: 8 + "px" }}>
                                                        <input type="Checkbox" style={{ marginRight: "10px" }} disabled checked={(() => {
                                                            try { if (this.state.schemaDataObject.SluttKontrollpunkter4.includes("_Na_")) { return true } }
                                                            catch{ }
                                                        }
                                                        )()}></input>
                                                    </td>
                                                    <td style={{ borderTop: 0 + "px" }}>
                                                        {this.state.schemaDataObject.SluttKontrollpunkter_Kommentar_4}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ borderTop: 0 + "px" }}>Er utstyr valgt og montert iht. produsentens anvisning?</td>
                                                    <td key={Math.random()} style={{ border: 0 + "px", padding: 8 + "px" }}>
                                                        <input type="Checkbox" style={{ marginRight: "10px" }} disabled checked={(() => {
                                                            try { if (this.state.schemaDataObject.SluttKontrollpunkter5.includes("_Ok_")) { return true } }
                                                            catch{ }
                                                        }
                                                        )()}></input>
                                                    </td>
                                                    <td key={Math.random()} style={{ border: 0 + "px", padding: 8 + "px" }}>
                                                        <input type="Checkbox" style={{ marginRight: "10px" }} disabled checked={(() => {
                                                            try { if (this.state.schemaDataObject.SluttKontrollpunkter5.includes("_Nok_")) { return true } }
                                                            catch{ }
                                                        }
                                                        )()}></input>
                                                    </td>
                                                    <td key={Math.random()} style={{ border: 0 + "px", padding: 8 + "px" }}>
                                                        <input type="Checkbox" style={{ marginRight: "10px" }} disabled checked={(() => {
                                                            try { if (this.state.schemaDataObject.SluttKontrollpunkter5.includes("_Na_")) { return true } }
                                                            catch{ }
                                                        }
                                                        )()}></input>
                                                    </td>
                                                    <td style={{ borderTop: 0 + "px" }}>
                                                        {this.state.schemaDataObject.SluttKontrollpunkter_Kommentar_5}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ borderTop: 0 + "px" }}>Er utstyret funksjonsprøvet og funnet i orden?</td>
                                                    <td key={Math.random()} style={{ border: 0 + "px", padding: 8 + "px" }}>
                                                        <input type="Checkbox" style={{ marginRight: "10px" }} disabled checked={(() => {
                                                            try { if (this.state.schemaDataObject.SluttKontrollpunkter6.includes("_Ok_")) { return true } }
                                                            catch{ }
                                                        }
                                                        )()}></input>
                                                    </td>
                                                    <td key={Math.random()} style={{ border: 0 + "px", padding: 8 + "px" }}>
                                                        <input type="Checkbox" style={{ marginRight: "10px" }} disabled checked={(() => {
                                                            try { if (this.state.schemaDataObject.SluttKontrollpunkter6.includes("_Nok_")) { return true } }
                                                            catch{ }
                                                        }
                                                        )()}></input>
                                                    </td>
                                                    <td key={Math.random()} style={{ border: 0 + "px", padding: 8 + "px" }}>
                                                        <input type="Checkbox" style={{ marginRight: "10px" }} disabled checked={(() => {
                                                            try { if (this.state.schemaDataObject.SluttKontrollpunkter6.includes("_Na_")) { return true } }
                                                            catch{ }
                                                        }
                                                        )()}></input>
                                                    </td>
                                                    <td style={{ borderTop: 0 + "px" }}>
                                                        {this.state.schemaDataObject.SluttKontrollpunkter_Kommentar_6}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ borderTop: 0 + "px" }}>Er nødvendig merking av utstyr utført?</td>
                                                    <td key={Math.random()} style={{ border: 0 + "px", padding: 8 + "px" }}>
                                                        <input type="Checkbox" style={{ marginRight: "10px" }} disabled checked={(() => {
                                                            try { if (this.state.schemaDataObject.SluttKontrollpunkter7.includes("_Ok_")) { return true } }
                                                            catch{ }
                                                        }
                                                        )()}></input>
                                                    </td>
                                                    <td key={Math.random()} style={{ border: 0 + "px", padding: 8 + "px" }}>
                                                        <input type="Checkbox" style={{ marginRight: "10px" }} disabled checked={(() => {
                                                            try { if (this.state.schemaDataObject.SluttKontrollpunkter7.includes("_Nok_")) { return true } }
                                                            catch{ }
                                                        }
                                                        )()}></input>
                                                    </td>
                                                    <td key={Math.random()} style={{ border: 0 + "px", padding: 8 + "px" }}>
                                                        <input type="Checkbox" style={{ marginRight: "10px" }} disabled checked={(() => {
                                                            try { if (this.state.schemaDataObject.SluttKontrollpunkter7.includes("_Na_")) { return true } }
                                                            catch{ }
                                                        }
                                                        )()}></input>
                                                    </td>
                                                    <td style={{ borderTop: 0 + "px" }}>
                                                        {this.state.schemaDataObject.SluttKontrollpunkter_Kommentar_7}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ borderTop: 0 + "px" }}>Foreligger dokumentasjon som er nødvendig for sikker bruk og vedlikehold?</td>
                                                    <td key={Math.random()} style={{ border: 0 + "px", padding: 8 + "px" }}>
                                                        <input type="Checkbox" style={{ marginRight: "10px" }} disabled checked={(() => {
                                                            try { if (this.state.schemaDataObject.SluttKontrollpunkter8.includes("_Ok_")) { return true } }
                                                            catch{ }
                                                        }
                                                        )()}></input>
                                                    </td>
                                                    <td key={Math.random()} style={{ border: 0 + "px", padding: 8 + "px" }}>
                                                        <input type="Checkbox" style={{ marginRight: "10px" }} disabled checked={(() => {
                                                            try { if (this.state.schemaDataObject.SluttKontrollpunkter8.includes("_Nok_")) { return true } }
                                                            catch{ }
                                                        }
                                                        )()}></input>
                                                    </td>
                                                    <td key={Math.random()} style={{ border: 0 + "px", padding: 8 + "px" }}>
                                                        <input type="Checkbox" style={{ marginRight: "10px" }} disabled checked={(() => {
                                                            try { if (this.state.schemaDataObject.SluttKontrollpunkter8.includes("_Na_")) { return true } }
                                                            catch{ }
                                                        }
                                                        )()}></input>
                                                    </td>
                                                    <td style={{ borderTop: 0 + "px" }}>
                                                        {this.state.schemaDataObject.SluttKontrollpunkter_Kommentar_8}
                                                    </td>
                                                </tr>
                                                
                                            </tbody>
                                        </Table>

                                        {/* ---------------- Anleggsbeskrivelse / Spesielle forhold / Eventuelle forbehold ----------------  */}
                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td style={{ borderTop: 0 + "px" }}><b>Anleggsbeskrivelse / Spesielle forhold / Eventuelle forbehold</b></td>
                                                </tr>
                                                <tr>
                                                    <td>{this.state.schemaDataObject.comment}</td>
                                                </tr>
                                            </tbody>
                                        </Table>

                                        <br></br><br></br>

                                        {/* ---------------- FOOTER ----------------  */}
                                        <SchemaFooter Date={schemaDataObject.Date} Montor={schemaDataObject.Montor} FooterText={this.props.footerText} CompanyURL={this.props.companyUrl} />
                                    </CardBody>
                                </div>
                            </Card>
                        </Col>
                    </Row>

                </div>
            </>
        )

    }
}

export default ForenkletSluttkontroll;

import React from "react";
// reactstrap components
import Loader from "components/Loader";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col
} from "reactstrap";
import SchemaFooter from "components/SchemaFooter";
import SchemaHeader from "components/SchemaHeader";
import { Base64 } from 'js-base64';


class Sluttkontroll extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      schemaDataObject: {},

      megging_KursArray: [],
      Megging_StederArray: [],
      Megging1Array: [],
      Megging2Array: [],
      Megging3Array: [],
      Megging4Array: [],
      Megging5Array: [],
      Megging6Array: [],
      MeggingCommentArray: [],
      MeggingDateArray: [],

      SpenningsfallKursArray: [],
      SpenningsfallStederArray: [],
      Spenningsfall1Array: [],
      Spenningsfall2Array: [],
      Spenningsfall3Array: [],
      Spenningsfall4Array: [],
      Spenningsfall5Array: [],
      Spenningsfall6Array: [],
      SpenningsfallCommentArray: [],
      SpenningsfallDateArray: [],

      ZLINE_KursArray: [],
      Steder3_Array: [],
      ZLINE_MArray: [],
      ZLINE_Lk2maxArray: [],
      ZLINE_lk3maxArray: [],
      ZLINE_DArray: [],
      ZLINE_Molested_Array: [],

      RCD_AmountArray: [],
      Steder5_Array: [],
      RCD_Moleresultat_1Array: [],
      RCD_Moleresultat_2Array: [],
      RCD_KommentarArray: [],
      RCD_DatoArray: [],
      RCD_Molsted_Array: [],

    };
  }

  componentDidMount() {

    this.setState({
      loading: true,
    });

    //Load schema data    
    fetch("https://rokeagruppenapi.azurewebsites.net/api/Customer/CustomerSchemaContent?jobId="+ this.props.jobId +"&schemaId=3", {
    //fetch("https://localhost:44347/api/Customer/CustomerSchemaContent?jobId=85292&schemaId=3", {
      method: "GET",
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + Base64.encode(sessionStorage.getItem("id") + ":" + sessionStorage.getItem("value")).replace(/['"]+/g, ''),
        'CompanyID': sessionStorage.getItem("companyID")
      },
    })
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result)
          //console.log(result)

          this.setState({
            schemaDataObject: result,
            megging_KursArray: result.Megging_Kurs,
            Megging_StederArray: result.Steder_,
            Megging1Array: result.Megging_1,
            Megging2Array: result.Megging_2,
            Megging3Array: result.Megging_3,
            Megging4Array: result.Megging_4,
            Megging5Array: result.Megging_5,
            Megging6Array: result.Megging_6,
            MeggingCommentArray: result.Megging_K,
            MeggingDateArray: result.Megging_Dato,

            SpenningsfallKursArray: result.Moling_Kurs,
            SpenningsfallStederArray: result.Steder2_,
            Spenningsfall1Array: result.Moling_1,
            Spenningsfall2Array: result.Moling_2,
            Spenningsfall3Array: result.Moling_3,
            Spenningsfall4Array: result.Moling_Sted_2,
            SpenningsfallCommentArray: result.Moling_K,
            SpenningsfallDateArray: result.Moling_Dato,

            ZLINE_KursArray: result.ZLINE_Kurs,
            Steder3_Array: result.Steder3_,
            ZLINE_MArray: result.ZLINE_M,
            ZLINE_Lk2maxArray: result.ZLINE_Lk2max,
            ZLINE_lk3maxArray: result.ZLINE_lk3max,
            ZLINE_DArray: result.ZLINE_D,
            ZLINE_Molested_Array: result.ZLINE_Molested_,

            CONTINUITY_KursArray: result.CONTINUITY_Kurs,
            Steder4_Array: result.Steder4_,
            CONTINUITY_MArray: result.CONTINUITY_M,
            CONTINUITY_MlArray: result.CONTINUITY_Ml,
            CONTINUITY_KArray: result.CONTINUITY_K,
            CONTINUITY_DArray: result.CONTINUITY_D,

            RCD_AmountArray: result.RCD_Amount,
            Steder5_Array: result.Steder5_,
            RCD_Moleresultat_1Array: result.RCD_Moleresultat_1,
            RCD_Moleresultat_2Array: result.RCD_Moleresultat_2,
            RCD_KommentarArray: result.RCD_Kommentar,
            RCD_DatoArray: result.RCD_Dato,
            RCD_Molsted_Array: result.RCD_Molsted_,

            loading: false
          });
        },
        (error) => {
          this.setState({
            loading: false,
            error: true
          });
        }
      )
  }

  renderSjekkpunktTableRow(jsonRef, jsonCommentRef, sjekkpunktText) {
    try{
      if (jsonRef.includes("_Ok_")) {
        return (
          <tr>
            <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} > {sjekkpunktText} </td>
            <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} ><input type="checkbox" disabled checked={true} style={{ marginRight: 30 + 'px' }} ></input></td>
            <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} ><input type="checkbox" disabled checked={false} style={{ marginRight: 30 + 'px' }} ></input></td>
            <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} ><input type="checkbox" disabled checked={false} style={{ marginRight: 30 + 'px' }} ></input></td>
            <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} >{jsonCommentRef}</td>
          </tr>
        )
      }
      if (jsonRef.includes("_Aktuelt_")) {
        return (
          <tr>
            <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} > {sjekkpunktText} </td>
            <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} ><input type="checkbox" disabled checked={false} style={{ marginRight: 30 + 'px' }} ></input></td>
            <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} ><input type="checkbox" disabled checked={true} style={{ marginRight: 30 + 'px' }} ></input></td>
            <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} ><input type="checkbox" disabled checked={false} style={{ marginRight: 30 + 'px' }} ></input></td>
            <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} >{jsonCommentRef}</td>
          </tr>
        )
      }
      if (jsonRef.includes("_Avvik_")) {
        return (
          <tr>
            <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} > {sjekkpunktText} </td>
            <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} ><input type="checkbox" disabled checked={false} style={{ marginRight: 30 + 'px' }} ></input></td>
            <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} ><input type="checkbox" disabled checked={false} style={{ marginRight: 30 + 'px' }} ></input></td>
            <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} ><input type="checkbox" disabled checked={true} style={{ marginRight: 30 + 'px' }} ></input></td>
            <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} >{jsonCommentRef}</td>
          </tr>
        )
      }
    }catch{}
  }

  renderMegging = () => {
    let table = []
    // loop to create parent
    for (let i = 1; i < this.state.megging_KursArray.length; i++) {
      let children = []
      children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} >{this.state.megging_KursArray[i]}</td>)
      children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} >{this.state.Megging_StederArray[i]}</td>)
      children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} >L1-L2: {this.state.Megging1Array[i]} - L2-L3: {this.state.Megging2Array[i]} - L1-L3: {this.state.Megging3Array[i]} - L1-j: {this.state.Megging4Array[i]}  - L2-j: {this.state.Megging5Array[i]} - L3-j: {this.state.Megging6Array[i]} Mohm (MΩ) </td>)
      children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} >{this.state.MeggingCommentArray[i]}</td>)
      children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} >{this.state.MeggingDateArray[i]}</td>)
      //Create the parent and add the children
      table.push(<tr key={Math.random()}>{children}</tr>)
    }
    table.push(<tr key={Math.random()}><td style={{ border: 0 + 'px', paddingTop: 10 + 'px' }}><input type="checkbox" disabled checked={this.state.schemaDataObject.ikke_aktuelt2} style={{ marginRight: 30 + 'px' }} ></input>Ikke aktuelt</td></tr>)
    return table
  }

  renderSpenningsfall = () => {
    let table = []
    // loop to create parent
    for (let i = 1; i < this.state.megging_KursArray.length; i++) {
      let children = []
      children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} >{this.state.SpenningsfallKursArray[i]}</td>)
      children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} >{this.state.SpenningsfallStederArray[i]}</td>)
      children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} >L1-L2: {this.state.Spenningsfall1Array[i]} - L2-L3: {this.state.Spenningsfall2Array[i]} - L1-L3: {this.state.Spenningsfall3Array[i]} </td>)
      children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} ><b>V</b> {this.state.Spenningsfall4Array[i]}</td>)
      children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} >{this.state.SpenningsfallCommentArray[i]}</td>)
      children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} >{this.state.SpenningsfallDateArray[i]}</td>)

      //Create the parent and add the children
      table.push(<tr key={Math.random()}>{children}</tr>)
    }
    table.push(<tr key={Math.random()}><td style={{ border: 0 + 'px', paddingTop: 10 + 'px' }} colSpan="4"><input type="checkbox" disabled checked={this.state.schemaDataObject.ikke_aktuelt3} style={{ marginRight: 30 + 'px' }} ></input>Ikke aktuelt</td></tr>)
    return table
  }

  renderSloyfeimpedans = () => {
    let table = []
    // loop to create parent
    for (let i = 1; i < this.state.megging_KursArray.length; i++) {
      let children = []
      try{children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} >{this.state.ZLINE_KursArray[i]}</td>)}
      catch{children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} ></td>)}
      try{children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} >{this.state.Steder3_Array[i]}</td>)}
      catch{children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} ></td>)}
      try{children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} >{this.state.ZLINE_Molested_Array[i]}</td>)}
      catch{children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} ></td>)}
      try{children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} >A {this.state.ZLINE_MArray[i]}</td>)}
      catch{children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} ></td>)}
      try{children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} >Ω {this.state.ZLINE_Lk2maxArray[i]}</td>)}
      catch{children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} ></td>)}
      try{children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} >A {this.state.ZLINE_lk3maxArray[i]}</td>)}
      catch{children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} ></td>)}
      try{children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} >Ikke aktuelt</td>)}
      catch{children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} ></td>)}
      try{children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} >{this.state.ZLINE_DArray[i]}</td>)}
      catch{children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} ></td>)}
      table.push(<tr key={Math.random()}>{children}</tr>)
      }
      
      //Create the parent and add the children
      
    
    table.push(<tr key={Math.random()}><td style={{ border: 0 + 'px', paddingTop: 10 + 'px' }} colSpan="4"><input type="checkbox" disabled checked={this.state.schemaDataObject.ikke_aktuelt4} style={{ marginRight: 30 + 'px' }} ></input>Ikke aktuelt</td></tr>)
    return table
  }

  renderKontinuitetsmaaling = () => {
    let table = []
    // loop to create parent
    for (let i = 1; i < this.state.megging_KursArray.length; i++) {
      let children = []
      try{children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} >{this.state.CONTINUITY_KursArray[i]}</td>)}
      catch{children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} ></td>)}
      try{children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} >{this.state.Steder4_Array[i]}</td>)}
      catch{children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} ></td>)}
      try{children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} >{this.state.CONTINUITY_MArray[i]}</td>)}
      catch{children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} ></td>)}
      try{children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} >ohm {this.state.CONTINUITY_MlArray[i]}</td>)}
      catch{children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} ></td>)}

      try{children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} >S {this.state.CONTINUITY_KArray[i]}</td>)}
      catch{children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} ></td>)}
      try{children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} >{this.state.RCD_AmountArray[i]}</td>)}
      catch{children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} ></td>)}

      children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} >{this.state.CONTINUITY_DArray[i]}</td>)

      //Create the parent and add the children
      table.push(<tr key={Math.random()}>{children}</tr>)
    }
    table.push(<tr key={Math.random()}><td style={{ border: 0 + 'px', paddingTop: 10 + 'px' }} colSpan="4"><input type="checkbox" disabled checked={this.state.schemaDataObject.ikke_aktuelt5} style={{ marginRight: 30 + 'px' }} ></input>Ikke aktuelt</td></tr>)
    return table
  }


  renderJordfeilbrytertest = () => {
    let table = []
    // loop to create parent
    for (let i = 1; i < this.state.megging_KursArray.length; i++) {
      let children = []
      try{children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} >{this.state.RCD_AmountArray[i]}</td>)}
      catch{children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} ></td>)}
      try{children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} >{this.state.Steder5_Array[i]}</td>)}
      catch{children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} ></td>)}
      try{children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} >{this.state.RCD_Molsted_Array[i]}</td>)}
      catch{children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} ></td>)}
      try{children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} >Ω {this.state.RCD_Moleresultat_1Array[i]}</td>)}
      catch{children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} ></td>)}
      try{children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} >{this.state.RCD_Moleresultat_2Array[i]}</td>)}
      catch{children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} ></td>)}
      try{children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} >{this.state.RCD_KommentarArray[i]}</td>)}
      catch{children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} ></td>)}
      try{children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} >{this.state.RCD_DatoArray[i]}</td>)}
      catch{children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} ></td>)}

      //Create the parent and add the children
      table.push(<tr key={Math.random()}>{children}</tr>)
    }
    table.push(<tr key={Math.random()}><td style={{ border: 0 + 'px', paddingTop: 10 + 'px' }} colSpan="4"><input type="checkbox" disabled checked={this.state.schemaDataObject.ikke_aktuelt6} style={{ marginRight: 30 + 'px' }} ></input>Ikke aktuelt</td></tr>)
    return table
  }



  render() {

    const tableStyle = {
      width: "100%",
    };

    const { error, loading, schemaDataObject } = this.state;

    if (loading) {
      return <Loader />
    }

    if (error) {
      return <h2>AUCH!!</h2>
    }

    return (
      <>
        <div className="content">

          <Row>
            <Col md="12">
              <Card>
                <SchemaHeader></SchemaHeader>

                <div id="printDiv">

                  <CardHeader>
                    <CardTitle tag="h4">SL-35-20 Verifikasjon - Sluttkontroll</CardTitle>
                  </CardHeader>

                  <CardBody>
                    <Table>
                      <tbody>
                        <tr>
                          <td><b>Kunde:</b></td>
                          <td>{schemaDataObject.Kunde}</td>
                          <td><b>Ordrenr:</b></td>
                          <td>{schemaDataObject.Ordrenr}</td>
                        </tr>
                        <tr>
                          <td><b>Adresse:</b></td>
                          <td>{schemaDataObject.Adresse}</td>
                          <td><b>Pnr/Sted:</b></td>
                          <td>{schemaDataObject.PnrSted}</td>
                        </tr>
                        <tr>
                          <td><b>Telefon:</b></td>
                          <td>{schemaDataObject.Tlfnr}</td>
                          <td><b>Epost:</b></td>
                          <td>{schemaDataObject.Epost}</td>
                        </tr>
                        <tr>
                          <td><b>Anleggstype:</b></td>
                          <td>{schemaDataObject.Anleggstype}</td>
                          <td><b>Kundenr. e-verk:</b></td>
                          <td>{schemaDataObject.Kundenreverk}</td>
                        </tr>
                        <tr>
                          <td><b>OV Størrelse:</b></td>
                          <td>{schemaDataObject.OVStorrelse}</td>
                          <td><b>Målepunktid:</b></td>
                          <td>{this.state.MalepunktID_}</td>
                        </tr>
                      </tbody>
                    </Table>


                    {/* ---------------- SJEKKPUNKTER ----------------  */}
                    <CardHeader>
                      <CardTitle><b>SJEKKPUNKTER:</b></CardTitle>
                    </CardHeader>
                    <table style={tableStyle}>
                      <tbody>
                        <tr>
                          <th>Visuell kontroll</th>
                          <th>Ok</th>
                          <th>Ikke aktuelt</th>
                          <th>Avvik</th>
                          <th>Kommentar</th>
                        </tr>


                        {this.renderSjekkpunktTableRow(schemaDataObject.Kontrollpunkter0, schemaDataObject.Kontrollpunkter_Kommentar_0, "Er utstyret CE merket og montert i henhold til monteringsanvisninger")}
                        {this.renderSjekkpunktTableRow(schemaDataObject.Kontrollpunkter1, schemaDataObject.Kontrollpunkter_Kommentar_1, "Er kabler og utstyr betryggende festet?")}
                        {this.renderSjekkpunktTableRow(schemaDataObject.Kontrollpunkter2, schemaDataObject.Kontrollpunkter_Kommentar_2, "Er alle kabler og ledninger forskriftsmessig avsluttet")}
                        {this.renderSjekkpunktTableRow(schemaDataObject.Kontrollpunkter3, schemaDataObject.Kontrollpunkter_Kommentar_3, "Er kapslingsgraden ivaretatt?")}
                        {this.renderSjekkpunktTableRow(schemaDataObject.Kontrollpunkter4, schemaDataObject.Kontrollpunkter_Kommentar_4, "Er jordelektrode tilkoblet, og at det ikke er blandet installasjon i samme «rom»?")}
                        {this.renderSjekkpunktTableRow(schemaDataObject.Kontrollpunkter5, schemaDataObject.Kontrollpunkter_Kommentar_5, "Er alle gjennomføringer i brannskiller tettet?")}
                        {this.renderSjekkpunktTableRow(schemaDataObject.Kontrollpunkter6, schemaDataObject.Kontrollpunkter_Kommentar_6, "Er effektbrytere og motorvern riktig justert?")}
                        {this.renderSjekkpunktTableRow(schemaDataObject.Kontrollpunkter7, schemaDataObject.Kontrollpunkter_Kommentar_7, "Er tilkoblinger foretatt med rett moment?")}
                        {this.renderSjekkpunktTableRow(schemaDataObject.Kontrollpunkter8, schemaDataObject.Kontrollpunkter_Kommentar_8, "Går strømmåler riktig vei?")}
                        {this.renderSjekkpunktTableRow(schemaDataObject.Kontrollpunkter9, schemaDataObject.Kontrollpunkter_Kommentar_9, "Er det valgt riktig type og utløserstrøm på jordfeilbrytere?")}
                        {this.renderSjekkpunktTableRow(schemaDataObject.Kontrollpunkter10, schemaDataObject.Kontrollpunkter_Kommentar_10, "Er det valgt nødvendig frakobling, sikkerhetsbryter, nødstopp og nullspennings utløser?")}
                        {this.renderSjekkpunktTableRow(schemaDataObject.Kontrollpunkter11, schemaDataObject.Kontrollpunkter_Kommentar_11, "Er IP grad tilpasset omgivelsene?")}
                        {this.renderSjekkpunktTableRow(schemaDataObject.Kontrollpunkter12, schemaDataObject.Kontrollpunkter_Kommentar_12, "Er nødvendig markering av ledere utført ?")}
                        {this.renderSjekkpunktTableRow(schemaDataObject.Kontrollpunkter13, schemaDataObject.Kontrollpunkter_Kommentar_13, "Er anlegget tilstrekkelig merket, ref 514,5,1 ?")}
                        {this.renderSjekkpunktTableRow(schemaDataObject.Kontrollpunkter14, schemaDataObject.Kontrollpunkter_Kommentar_14, "Er det tatt hensyn til strømføringsevne, tab 52A?")}
                        {this.renderSjekkpunktTableRow(schemaDataObject.Kontrollpunkter15, schemaDataObject.Kontrollpunkter_Kommentar_15, "Er selektivitet ivaretatt?")}
                        {this.renderSjekkpunktTableRow(schemaDataObject.Kontrollpunkter16, schemaDataObject.Kontrollpunkter_Kommentar_16, "Er alle tilkoblinger riktig utført ?")}
                        {this.renderSjekkpunktTableRow(schemaDataObject.Kontrollpunkter17, schemaDataObject.Kontrollpunkter_Kommentar_17, "Er skjult varme dokumentert og fotografert?")}
                        {this.renderSjekkpunktTableRow(schemaDataObject.Kontrollpunkter18, schemaDataObject.Kontrollpunkter_Kommentar_18, "Er nødvendig dokumentasjon / informasjon og brukerveiledninger overlevert eier?")}
                        {this.renderSjekkpunktTableRow(schemaDataObject.Kontrollpunkter19, schemaDataObject.Kontrollpunkter_Kommentar_19, "Er advarseltakster montert?")}
                        {this.renderSjekkpunktTableRow(schemaDataObject.Kontrollpunkter20, schemaDataObject.Kontrollpunkter_Kommentar_20, "Er det ryddet opp på arbeidsstedet og avfall behandlet etter gjeldende instruks?")}

                        <tr>
                          <td style={{ colSpan: "4" }}><b>Våtrom</b></td>
                        </tr>

                        {this.renderSjekkpunktTableRow(schemaDataObject.Kontrollpunkter21, schemaDataObject.Kontrollpunkter_Kommentar_21, "Er jordfeilbryter testet?")}
                        {this.renderSjekkpunktTableRow(schemaDataObject.Kontrollpunkter22, schemaDataObject.Kontrollpunkter_Kommentar_22, "Er det tatt hensyn til soner?")}
                        {this.renderSjekkpunktTableRow(schemaDataObject.Kontrollpunkter23, schemaDataObject.Kontrollpunkter_Kommentar_23, "Er utjevningsforbindelser montert og kontrollert?")}
                        {this.renderSjekkpunktTableRow(schemaDataObject.Kontrollpunkter24, schemaDataObject.Kontrollpunkter_Kommentar_24, "Er alle tilkoblinger lett tilgjengelig?")}

                        <tr>
                          <td style={{ colSpan: "4" }}><b>Generelt</b></td>
                        </tr>

                        {this.renderSjekkpunktTableRow(schemaDataObject.Kontrollpunkter25, schemaDataObject.Kontrollpunkter_Kommentar_25, "Er spenningsfall målt og notert?")}
                        {this.renderSjekkpunktTableRow(schemaDataObject.Kontrollpunkter26, schemaDataObject.Kontrollpunkter_Kommentar_26, "Er det oppdatert informasjon i sikringsskap ref 514.5.1 ?")}
                        {this.renderSjekkpunktTableRow(schemaDataObject.Kontrollpunkter27, schemaDataObject.Kontrollpunkter_Kommentar_27, "Er det tatt hensyn til IK 3polt maks?")}

                        <tr>
                          <td style={{ colSpan: "4" }}><b>Ved arbeid i anlegg med OV over 63A og/eller 25 kA</b></td>
                        </tr>

                        {this.renderSjekkpunktTableRow(schemaDataObject.Kontrollpunkter28, schemaDataObject.Kontrollpunkter_Kommentar_28, "Er det montert tilsvarende værn som sto i tavlen?")}
                        {this.renderSjekkpunktTableRow(schemaDataObject.Kontrollpunkter29, schemaDataObject.Kontrollpunkter_Kommentar_29, "Er det montert kabler med ekvivalent mm2?")}

                      </tbody>
                    </table>


                    {/* ---------------- OVERGANGSMOTSTAND ----------------  */}
                    <CardHeader>
                      <CardTitle><b>Overgangsmotstand på jordelektroder R, ? EARTH</b></CardTitle>
                      <p>F.eks 500Ω ved 100mA jordfeilbryter foran hele anlegget:</p>
                    </CardHeader>
                    <table style={tableStyle}>
                      <tbody>
                        <tr>
                          <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} colSpan="1"><b>Målested</b></td>
                          <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} colSpan="1"><b>Måleresultat</b></td>
                          <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} colSpan="1"><b>Kommentar</b></td>
                          <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} colSpan="1"><b>Dato/Sign</b></td>
                        </tr>
                        <tr>
                          <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} colSpan="1">Mellom E-verksjord og jordelektrode</td>
                          <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} colSpan="1">Ω {schemaDataObject.Overgangsmotstand_Moleresultat_0}</td>
                          <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} colSpan="1"></td>
                          {/* -->> DATA FOR KOMMENTAR BLIR IKKE MED FRA SKJEMAET */}
                          <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} colSpan="1">{schemaDataObject.Overgangsmotstand_Dato_0}</td>
                        </tr>
                        <tr>
                          <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} colSpan="1">Mellom målespyd og jordelektrode</td>
                          <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} colSpan="1">Ω {schemaDataObject.Overgangsmotstand_Moleresultat_1}</td>
                          <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} colSpan="1"></td>
                          {/* -->> DATA FOR KOMMENTAR BLIR IKKE MED FRA SKJEMAET */}
                          <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} colSpan="1">{schemaDataObject.Overgangsmotstand_Dato_1}</td>
                        </tr>
                        <tr>
                          <td style={{ border: 0 + 'px', paddingTop: 10 + 'px' }}><input type="checkbox" disabled checked={schemaDataObject.ikke_aktuelt1} style={{ marginRight: 30 + 'px' }} ></input>Ikke aktuelt</td>
                        </tr>
                      </tbody>
                    </table>


                    {/* ---------------- Måling av isolasjonsresistans (Megging) RISO ----------------  */}
                    <CardHeader>
                      <CardTitle><b>Måling av isolasjonsresistans (Megging) RISO:</b></CardTitle>
                      <p>Resultatet skal være > 1MΩ</p>
                    </CardHeader>
                    <table style={tableStyle}>
                      <tbody>
                        {/* <tr style={mystyle}>
                          <th style={{ border: 1 + "px solid black", padding: 8 + "px" }} colSpan="2">Måling av isolasjonsresistans (Megging) RISO</th>
                          <th style={{ border: 1 + "px solid black", padding: 8 + "px" }} colSpan="3">Resultatet skal være > 1MΩ</th>
                        </tr> */}
                        <tr>
                          <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} colSpan="1"><b>Kurs nr.</b></td>
                          <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} colSpan="1"><b>Berørte steder</b></td>
                          <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} colSpan="1"><b>Måleresultat</b></td>
                          <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} colSpan="1"><b>Kommentar</b></td>
                          <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} colSpan="1"><b>Dato/Sign</b></td>
                        </tr>

                        {this.renderMegging()}

                      </tbody>
                    </table>


                    {/* ---------------- Måling av fasespenninger (spenningsfall) ----------------  */}
                    <CardHeader>
                      <CardTitle><b>Måling av fasespenninger (spenningsfall):</b></CardTitle>
                    </CardHeader>
                    <table style={tableStyle}>
                      <tbody>
                        <tr>
                          <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} colSpan="1"><b>Kurs nr.</b></td>
                          <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} colSpan="1"><b>Berørte steder</b></td>
                          <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} colSpan="1"><b>Måleresultat</b></td>
                          <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} colSpan="1"><b>Måleresultat lengste koblingspunkt</b></td>
                          <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} colSpan="1"><b>Kommentar</b></td>
                          <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} colSpan="1"><b>Dato/Sign</b></td>
                        </tr>

                        {this.renderSpenningsfall()}

                      </tbody>
                    </table>

                    {/* ---------------- Sløyfeimpedans / Kortslutningsmåling ZLINE ----------------  */}
                    <CardHeader>
                      <CardTitle><b>Sløyfeimpedans / Kortslutningsmåling ZLINE:</b></CardTitle>
                      <p>lk2min må være høyere enn 4,8xln på B-kar. / 9,5xln på C-kar. 15xln på D-kar. OBS! Hvis lk3max over 10kA</p>
                    </CardHeader>
                    <table style={tableStyle}>
                      <tbody>
                        <tr>
                          <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} colSpan="1"><b>Kurs nr.</b></td>
                          <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} colSpan="1"><b>Berørte steder</b></td>
                          <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} colSpan="1"><b>Målested</b></td>
                          <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} colSpan="2"><b>Måleresultat = Ik2max</b></td>
                          <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} colSpan="1"><b>lk3max = Ik2max*1,15</b></td>
                          <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} colSpan="1"><b>lk2min = Ik2max*0,76</b></td>
                          <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} colSpan="1"><b>Dato/Sign</b></td>
                        </tr>

                        {this.renderSloyfeimpedans()}

                      </tbody>
                    </table>


                    {/* ---------------- Sløyfeimpedans / Kortslutningsmåling ZLINE ----------------  */}
                    <CardHeader>
                      <CardTitle><b>Kontinuitetsmåling Utjevningsforbindelser R?200mA, CONTINUITY Still inninstrumentet på R?200mA:</b></CardTitle>
                      <p>Typisk resultat > 1 Ω</p>
                    </CardHeader>
                    <table style={tableStyle}>
                      <tbody>
                        <tr>
                          <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} colSpan="1"><b>Kurs nr.</b></td>
                          <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} colSpan="1"><b>Berørte steder</b></td>
                          <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} colSpan="1"><b>Målested</b></td>
                          <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} colSpan="1"><b>Måleresultat</b></td>
                          <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} colSpan="1"><b>Kommentar</b></td>
                          <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} colSpan="1"><b>Dato/Sign</b></td>
                        </tr>

                        {this.renderKontinuitetsmaaling()}

                      </tbody>
                    </table>

                    {/* ---------------- Jordfeilbrytertest ----------------  */}
                    <CardHeader>
                      <CardTitle><b>Jordfeilbrytertest RCD:</b></CardTitle>
                      <p>	Typisk resultat mellom 18 og 30mA for 30mA JFB</p>
                    </CardHeader>
                    <table style={tableStyle}>
                      <tbody>
                        <tr>
                          <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} colSpan="1"><b>Kurs nr.</b></td>
                          <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} colSpan="1"><b>Berørte steder</b></td>
                          <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} colSpan="1"><b>Målested</b></td>
                          <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} colSpan="2"><b>Måleresultat</b></td>
                          <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} colSpan="1"><b>Kommentar</b></td>
                          <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} colSpan="1"><b>Dato/Sign</b></td>
                        </tr>

                        {this.renderJordfeilbrytertest()}

                      </tbody>
                    </table>

                    {/* ---------------- Siste greiene ----------------  */}
                    <table style={tableStyle}>
                      <tbody>
                        <tr>
                          <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} colSpan="1"><b>Egenkontrollskjemaer</b></td>
                          <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} colSpan="1"><b>Kommentar</b></td>
                          <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} colSpan="1"><b>Dato/Sign</b></td>
                        </tr>
                        <tr>
                          <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} colSpan="1">Er alle egenkontrollskjemaer utfylt</td>
                          <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} colSpan="1">{schemaDataObject.Egenkontrollskjemaer_K_1}</td>
                          <td style={{ border: 1 + "px solid black", padding: 8 + "px" }} colSpan="1">{schemaDataObject.Egenkontrollskjemaer_D_1}</td>
                        </tr>
                      </tbody>
                    </table>

                    {/* ---------------- KOMMENTAR ----------------  */}
                    <CardHeader>
                      <CardTitle><b>Kommentar:</b></CardTitle>
                    </CardHeader>
                    <table>
                      <tbody>
                        <tr>
                          <td>{schemaDataObject.comment}</td>
                        </tr>
                      </tbody>
                    </table>

                    <br></br><br></br>

                    {/* ---------------- FOOTER ----------------  */}
                    <SchemaFooter Date={schemaDataObject.Date} Montor={schemaDataObject.Montor} FooterText={this.props.footerText} CompanyURL={this.props.companyUrl} />


                  </CardBody>
                </div>
              </Card>
            </Col>
          </Row>

        </div>
      </>
    )

  }
}

export default Sluttkontroll;
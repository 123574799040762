import React from "react";
import classnames from "classnames";
// reactstrap components
import {
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";

import PictureUpload from "components/CustomUpload/PictureUpload.jsx";
import Loader from "components/Loader";

class Wizard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      username: "",
      phone: "",
      email: "",
      usernameState: "",
      phoneState: "",
      emailState: "",
      imageUrl: null
    };
  }

  // function that returns true if value is email, false otherwise
  verifyEmail = value => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
      return false;
  };


  isEmailAvailable = value => {
    this.setState({ 
      loading: true,
      emailFocus : false,
      email: value.target.value
    });

    //extra check to prevent a bug where user blurs email field and unvlid email are validated on DB
    if (!this.verifyEmail(value.target.value)) {
      this.setState({ 
        emailState: "has-danger",
        loading: false
      });
      document.getElementsByName('email')[0].select()
      return false;
    }
    
    fetch("https://rokeagruppenapi.azurewebsites.net/api/Account/ValidateEmail", {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' +sessionStorage.access_token.replace(/['"]+/g, '')
      },
      body: JSON.stringify({
        Email: value.target.value
      }) 
    })   
    .then(
      (result) => {
        if (result.status === 200)  {
          console.log("Email is available")
          this.setState({
          loading: false,
          emailState: "has-success"
        });
        document.getElementsByName('username')[0].select()
        } else {
          console.log("Email is not available")
          this.setState({
            emailState:"has-danger",
            loading: false
        });
        document.getElementsByName('email')[0].select()
        }
      },
      (error) => {
        this.setState({
          loading: false,
          error
        });
        console.log(error)
      }
    )
    return true;
  }

  
  isUsernameAvailable = value => {

    this.setState({ 
      loading: true,
      usernameFocus : false,
      username: value.target.value
    });

    fetch("https://rokeagruppenapi.azurewebsites.net/api/Account/ValidateUserName", {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+sessionStorage.access_token.replace(/['"]+/g, '')
      },
      body: JSON.stringify({
        USerName: value.target.value
      }) 
    })   
    .then(
      (result) => {
        if (result.status === 200)  {
          console.log("UserName is available")
          this.setState({
            loading: false
          });
          document.getElementsByName('phone')[0].select()
        } else {
          console.log("UserName is not available")
          this.setState({
            usernameState:"has-danger",
            loading: false
          });
          document.getElementsByName('username')[0].select()
        }
      },
      (error) => {
        this.setState({
          loading: false,
          error
        });
        console.log(error)
      }
    )
    return true;
  }

  verifyPhone  = (value, length) => {
    if (value.length >= length && value.length < 13) {
      var phoneRex = /^^[0-9]\d{7,11}$/;
      if (phoneRex.test(value)) {
        return true;
      }
    }
    return false;
  };

  // function that verifies if a string has a given length or not
 verifyLength  = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };

  change = (event, stateName, type, stateNameEqualTo, maxValue) => {
    switch (type) {
      case "email":
          if (this.verifyEmail(event.target.value)) {
            this.setState({ [stateName + "State"]: "has-success" });
          } else {
            this.setState({ [stateName + "State"]: "has-danger" });
          }
          break;
      case "userName":
          if (this.verifyUserName(event.target.value)) {
            this.setState({ [stateName + "State"]: "has-success" });
          } else {
            this.setState({ [stateName + "State"]: "has-danger" });
          }
          break;
      case "phone":
        if (this.verifyPhone(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      default:
        break;
    }
    this.setState({ [stateName]: event.target.value });
  };
  
  isValidated = () => {
    if (
      this.state.usernameState === "has-success" &&
      this.state.phoneState === "has-success" &&
      this.state.emailState === "has-success"
    ) {
      return true;
    } else {
      if (this.state.usernameState !== "has-success") {
        this.setState({ usernameState: "has-danger" });
      }
      if (this.state.phoneState !== "has-success") {
        this.setState({ phoneState: "has-danger" });
      }
      if (this.state.emailState !== "has-success") {
        this.setState({ emailState: "has-danger" });
      }
      return false;
    }
  };

  pictureCallback = (dataFromChild) => {
    this.setState({
      imageUrl: dataFromChild
    })
  };



  render() {

    const { loading } = this.state;

    if (loading) {
      return <Loader />
    }

    return (
      <>
        <h5 className="info-text">
        Requires an unique email. User name and mobile phone are required. Picture is optional.
        </h5>
        <Row className="justify-content-center">
          <Col sm="4">
            <PictureUpload callbackFromParent={this.pictureCallback} imageUrl={this.state.imageUrl}/>
          </Col>
          <Col sm="6">

            <InputGroup
              className={classnames(this.state.emailState, {
                "input-group-focus": this.state.emailFocus
              })}
            >
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="nc-icon nc-send" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                name="email"
                placeholder="Email (required)"
                type="email"
                value= { this.state.email }
                onChange={e => this.change(e, "email", "email")}
                onFocus={e => this.setState({ emailFocus: true })}
                onBlur={e => this.isEmailAvailable(e)}               
              />
              {this.state.emailState === "has-danger" ? (
                  <label className="error">A valid and unique email address is required</label>
                ) : null}

            </InputGroup>
            

            <InputGroup
                className={classnames(this.state.usernameState, {
                  "input-group-focus": this.state.usernameFocus
                })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="nc-icon nc-single-02" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  name="username"
                  placeholder="User Name (required)"
                  type="text"
                  value= { this.state.username }
                  onChange={e => this.change(e, "username", "length", 3)}
                  onFocus={e => this.setState({ usernameFocus: true })}
                  onBlur={e => this.isUsernameAvailable(e) }
                />
                {this.state.usernameState === "has-danger" ? (
                  <label className="error">Unique username required. Minimum 3 caracters.</label>
                ) : null}
              </InputGroup>

            
              <InputGroup
                className={classnames(this.state.phoneState, {
                  "input-group-focus": this.state.phoneFocus
                })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="nc-icon nc-mobile" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  name="phone"
                  placeholder="Mobile phone (required)"
                  type="text"
                  value= { this.state.phone }
                  onChange={e => this.change(e, "phone", "phone", 8)}
                  onFocus={e => this.setState({ phoneFocus: true })}
                  onBlur={e => this.setState({ phoneFocus: false })}
                />
                {this.state.phoneState === "has-danger" ? (
                  <label className="error">This field is required. 8-12 digits.</label>
                ) : null}
              </InputGroup>

          </Col>
        </Row>
      </>
    );
  }
}

export default Wizard;

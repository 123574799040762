import React from "react";
import { Redirect } from 'react-router-dom';

import { Route } from 'react-router-dom'
import AdminLayout from "layouts/Admin.jsx";

import Loader from "../components/Loader"
//import { func } from "prop-types";

export class AuthRequired extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      validToken: false,
      error: null,
      isAdmin: false,
    };
  }

  componentDidMount() {
    let currentComponent = this;
    var date = new Date();

    if ( (sessionStorage.length > 0) & (date.getTime() - sessionStorage.time < 900000) ) {
      currentComponent.setState({
        loading: false,
        validToken: true,
      });
    }
    else
    {
      currentComponent.setState({
        loading: false,
        validToken: false,
      });

    }
  }


  render() {

    const { loading, validToken, isAdmin } = this.state;
    var isUserAdmin;
    if (isAdmin === "true") {
      isUserAdmin = true
    } else {
      isUserAdmin = true
    }

    if (loading) {
      return <Loader />
    }

    if (!validToken) {
      return (<Redirect to='/auth/login' />);
    } else {
      return (<Route path="/admin" render={props => <AdminLayout {...props} isAdmin={isUserAdmin} />} />);
    }
  }
}
export default AuthRequired;
import React from "react";


class Loader extends React.Component {
    render() {
      return (
        <div className="spinner" >
      </div>
      );
    }
}

export default Loader;
import React from "react";
import {
    Table,
} from "reactstrap";

class SchemaFooter extends React.Component {

    
    render() {        
        return (
            <>
                <table>
                    <tbody>
                        <tr>
                            <td style={{ paddingRight: 30 + 'px', paddingLeft: 30 + 'px' }} ><b>Dato:</b> {this.props.Date}</td>
                            <td style={{ marginRight: 30 + 'px', marginLeft: 30 + 'px' }} ><b>Montør:</b> {this.props.Montor}</td>
                        </tr>
                    </tbody>
                </table>

                <br></br><br></br>

                <Table>
                    <tbody>
                        <tr>
                            <td style={{ textAlign: 'center', borderTop: 0 + 'px' }}>{this.props.FooterText}<br></br><a target="top" href={this.props.CompanyURL}>{this.props.CompanyURL} </a></td>
                        </tr>
                        <tr>

                        </tr>
                        <tr>
                            <td style={{ textAlign: 'center', borderTop: 0 + 'px' }}><img alt="logo" style={{width:200 +'px'}} src={sessionStorage.getItem("companyLogoUrl")}></img></td>
                        </tr>
                    </tbody>
                </Table>
            </>
        )
    }
}

export default SchemaFooter;
import React from "react";
import {
    CardHeader,
    Button
} from "reactstrap";

import ReactToPrint from "react-to-print";


class SchemaHeader extends React.Component {

    
    render() {
        return (
            <>
                <CardHeader>
                  <div className="pull-left">
                    <Button
                      onClick={() => {
                        window.location = "../admin/elektriker";
                      }}
                      className="btn-round btn-icon"
                      color="success"
                      size="sm"
                    >
                      <i className="nc-icon nc-minimal-left" />
                    </Button>
                    <span style={{ marginLeft: 10 + "px" }}>Tilbake til servicejobber</span>
                  </div>

                  <div className="pull-right">
                    <span style={{ marginLeft: 10 + "px" }}>
                      <ReactToPrint
                        trigger={() =>
                          <div>
                            <Button
                              className="btn-round btn-icon"
                              color="alert"
                              size="sm"
                            >
                              <i className="nc-icon nc-paper" />
                            </Button>
                            <span style={{ marginLeft: 10 + "px" }}>Skriv ut skjema</span>
                          </div>
                        }
                        content={() => document.getElementById("printDiv")}
                      />
                    </span>
                  </div>
                </CardHeader>
            </>
        )
    }
}

export default SchemaHeader;

import React from "react";
// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Table,
    Row,
    Col
} from "reactstrap";
import Loader from "components/Loader";
import SchemaFooter from "components/SchemaFooter";
import SchemaHeader from "components/SchemaHeader";
import { Base64 } from 'js-base64';


class Tilstandsrapport extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            loading: true,
            schemaDataObject: {},
            avvikCol1Array: [],
            avvikCol2Array: [],
            tiltakArray: [],
        };
    }

    componentDidMount() {

        //Load schema data
        fetch("https://rokeagruppenapi.azurewebsites.net/api/Customer/CustomerSchemaContent?jobId=" + this.props.jobId + "&schemaId=4", {
            method: "GET",
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + Base64.encode(sessionStorage.getItem("id") + ":" + sessionStorage.getItem("value")).replace(/['"]+/g, ''),
                'CompanyID': sessionStorage.getItem("companyID")
            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        loading: false,
                        schemaDataObject: result,
                        avvikCol1Array: result.textarea01,
                        avvikCol2Array: result.textarea02,
                        avvikCol6Array: result.text1,
                        tiltak1Array: result.textarea03,
                        tiltak2Array: result.textarea04,
                    });
                },
                (error) => {
                    this.setState({
                        loading: false,
                        error: true
                    });
                }
            )
    }


    checkIfChecked(val) {
        let checkboxId = 'checkbox0' + val

        try {
            let test = JSON.stringify(Object.keys(this.state.schemaDataObject[checkboxId]))

            switch (test) {
                case '["1"]':
                    return ([
                        <td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} ><input type='checkbox' disabled checked={true} style={{ marginRight: 30 + 'px' }} ></input></td>, <td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} ><input type='checkbox' disabled checked={false} style={{ marginRight: 30 + 'px' }} ></input></td>, <td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} ><input type='checkbox' disabled checked={false} style={{ marginRight: 30 + 'px' }} ></input></td>,
                    ]);
                case '["2"]':
                    return ([
                        <td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} ><input type='checkbox' disabled checked={false} style={{ marginRight: 30 + 'px' }} ></input></td>, <td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} ><input type='checkbox' disabled checked={true} style={{ marginRight: 30 + 'px' }} ></input></td>, <td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} ><input type='checkbox' disabled checked={false} style={{ marginRight: 30 + 'px' }} ></input></td>,
                    ]);
                case '["3"]':
                    return ([
                        <td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} ><input type='checkbox' disabled checked={false} style={{ marginRight: 30 + 'px' }} ></input></td>, <td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} ><input type='checkbox' disabled checked={false} style={{ marginRight: 30 + 'px' }} ></input></td>, <td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} ><input type='checkbox' disabled checked={true} style={{ marginRight: 30 + 'px' }} ></input></td>,
                    ]);
                default:
                    {
                        return;
                    }
            }

        } catch (e) {
            return ([

            ]);
        }
    }


    checkIfLastTableChecked(text, checkBoxId) {
        try {
            let test = JSON.stringify(Object.keys(this.state.schemaDataObject[checkBoxId]))

            switch (test) {
                case '["1"]':
                    return ([
                        <td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} >{text}</td>,
                        <td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} ><input type='checkbox' disabled checked={true} style={{ marginRight: 30 + 'px' }} ></input></td>,
                        <td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} ><input type='checkbox' disabled checked={false} style={{ marginRight: 30 + 'px' }} ></input></td>,
                    ]);
                case '["2"]':
                    return ([
                        <td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} >{text}</td>,
                        <td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} ><input type='checkbox' disabled checked={false} style={{ marginRight: 30 + 'px' }} ></input></td>,
                        <td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} ><input type='checkbox' disabled checked={true} style={{ marginRight: 30 + 'px' }} ></input></td>,
                    ]);
                default:
                    {
                        return;
                    }
            }


        } catch (e) {
            return ([

            ]);
        }
    }

    renderRegistrerteAvvik = () => {
        let table = []
        // loop to create parent
        for (let i = 1; i < this.state.avvikCol1Array.length; i++) {
            let children = []
            children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} >{this.state.avvikCol1Array[i]}</td>)
            children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} >{this.state.avvikCol2Array[i]}</td>)
            children.push(this.checkIfChecked(i))
            children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} >{this.state.avvikCol6Array[i]}</td>)

            //Create the parent and add the children
            table.push(<tr key={Math.random()}>{children}</tr>)
        }
        return table
    }


    renderKorrigerendeTiltak = () => {
        try {

            let table = []
            // loop to create parent
            for (let i = 1; i < this.state.tiltak1Array.length; i++) {
                let children = []
                children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} >{this.state.tiltak1Array[i]}</td>)
                children.push(<td key={Math.random()} style={{ border: 1 + "px solid black", padding: 8 + "px" }} >{this.state.tiltak2Array[i]}</td>)
                //Create the parent and add the children
                table.push(<tr key={Math.random()}>{children}</tr>)
            }
            return table

        } catch (e) {
            return ([

            ]);
        }
    }

    renderLastTable = () => {
        let table = []
        let children = []
        let children1 = []
        let children2 = []
        children.push(this.checkIfLastTableChecked('Var feilene/ avvik grunnet ufaglært arbeidskraft?', 'checkbox05'))
        table.push(<tr key={Math.random()}>{children}</tr>)
        children1.push(this.checkIfLastTableChecked('Var feilene/ avvik grunnet for gammelt installasjon', 'checkbox06'))
        table.push(<tr key={Math.random()}>{children1}</tr>)
        children2.push(this.checkIfLastTableChecked('Ble det tatt bilder av tilstand før korrigerende tiltak?', 'checkbox07'))
        table.push(<tr key={Math.random()}>{children2}</tr>)
        return table
    }


    render() {

        const { error, loading, schemaDataObject } = this.state;
        if (loading) {
            return <Loader />
        }

        if (error) {
            return <h2>AUCH!!</h2>
        }

        return (
            <>
                <div className="content">

                    <Row>
                        <Col md="12">
                            <Card>
                                <SchemaHeader></SchemaHeader>

                                <div id="printDiv">

                                    <CardHeader>
                                        <CardTitle tag="h4">Tilstandsrapport for det elektriske</CardTitle>
                                    </CardHeader>

                                    <CardBody>
                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td><b>Saksbehandler:</b></td>
                                                    <td>{schemaDataObject.textfield2}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ borderTop: 0 + 'px' }} ><b>Kontroll dato:</b></td>
                                                    <td style={{ borderTop: 0 + 'px' }} >{schemaDataObject.Date}</td>

                                                </tr>
                                                <tr>
                                                    <td style={{ borderTop: 0 + 'px' }} ><b>Kontroll klokkeslett:</b></td>
                                                    <td style={{ borderTop: 0 + 'px' }} >{schemaDataObject.textfield3}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ borderTop: 0 + 'px' }} ><b>Anleggsadresse:</b></td>
                                                    <td style={{ borderTop: 0 + 'px' }} >{schemaDataObject.FullJobAddress}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ borderTop: 0 + 'px' }} ><b>Kundens adresse:</b></td>
                                                    <td style={{ borderTop: 0 + 'px' }} >{schemaDataObject.FullCompanyAddress}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ borderTop: 0 + 'px' }} ><b>Faktura grunnlagsnummer:</b></td>
                                                    <td style={{ borderTop: 0 + 'px' }} >{schemaDataObject.textfield6}</td>
                                                </tr>
                                                <tr>
                                                    {/* BUG - IKKE MED I DATASET */}
                                                    <td style={{ borderTop: 0 + 'px' }} ><b>Måling objektnummer:</b></td>
                                                    <td style={{ borderTop: 0 + 'px' }} >{schemaDataObject.MalepunktID}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ borderTop: 0 + 'px' }} ><b>Byggeår:</b></td>
                                                    <td style={{ borderTop: 0 + 'px' }} >{schemaDataObject.Byggaar}</td>
                                                </tr>

                                            </tbody>
                                        </Table>


                                        {/* ---------------- REGISTRERTE AVVIK ----------------  */}
                                        <CardHeader>
                                            <CardTitle><b>Registrert avvik</b></CardTitle>
                                        </CardHeader>
                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td><b>Sted:</b></td>
                                                    <td><b>Beskrivelse:</b></td>
                                                    <td><b>Veldig farlig:</b></td>
                                                    <td><b>Farlig:</b></td>
                                                    <td><b>Ikke farlig:</b></td>
                                                    <td><b>Norm-/ Forskrkiftsref.:</b></td>
                                                </tr>

                                                {this.renderRegistrerteAvvik()}

                                            </tbody>
                                        </Table>

                                        {/* ---------------- KORRIGERENDE TILTAK ----------------  */}
                                        <CardHeader>
                                            <CardTitle><b>Korrigerende tiltak</b></CardTitle>
                                        </CardHeader>
                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td><b>Sted:</b></td>
                                                    <td><b>Hva bør gjøres:</b></td>
                                                </tr>

                                                {this.renderKorrigerendeTiltak()}
                                            </tbody>
                                        </Table>


                                        {/* ---------------- LAST TABLE ----------------  */}

                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td style={{ borderTop: 0 + 'px' }}></td>
                                                    <td style={{ borderTop: 0 + 'px' }}><b>JA</b></td>
                                                    <td style={{ borderTop: 0 + 'px' }}><b>Nei</b></td>
                                                </tr>

                                                {this.renderLastTable()}

                                            </tbody>
                                        </Table>

                                        <CardHeader>
                                            <CardTitle><b>Merknader</b></CardTitle>
                                        </CardHeader>

                                        {schemaDataObject.textarea}

                                        <br></br><br></br>

                                        {/* ---------------- FOOTER ----------------  */}

                                        <SchemaFooter Date={schemaDataObject.Date} Montor={schemaDataObject.Montor} FooterText={this.props.footerText} CompanyURL={this.props.companyUrl} />


                                    </CardBody>
                                </div>
                            </Card>
                        </Col>
                    </Row>

                </div>
            </>
        )

    }
}

export default Tilstandsrapport;
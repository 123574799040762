import React from "react";
import Loader from "components/Loader";
import { Base64 } from 'js-base64';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Label,
  Row,
  Col
} from "reactstrap";

class UserProfile extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: [],
      customerProfile: null,
    };

    this.onFormSubmit = this.onFormSubmit.bind(this);

  }

  componentDidMount() {
    //fetch("https://localhost:44347/api/Customer/CustomerProfile", {
    fetch("https://rokeagruppenapi.azurewebsites.net/api/Customer/CustomerProfile", {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + Base64.encode(sessionStorage.getItem("id") +":"+sessionStorage.getItem("value")).replace(/['"]+/g, ''),
        'CompanyID': sessionStorage.getItem("companyID")
      },
    })
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            customerProfile: result,
            isLoaded: true
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          //handle error
        }
      )
  }

  onFormSubmit = ()=>{    
    
}

  render() {

    const { error, isLoaded, customerProfile } = this.state;

    if (error) {
      return <div>Error: {error.message}</div>;
    }

    if (!isLoaded) {
      return <Loader />
    }

    if (isLoaded) {

      return (
        <>
          <div className="content">
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Min kundeinformasjon</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <Row>
                        <Col className="pr-1" md="12">
                          <FormGroup>
                            <label>Kundenavn</label>
                            <Input
                              defaultValue={customerProfile.companyName}
                              disabled
                              placeholder="Kundenavn"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-1" md="6">
                          <FormGroup>
                            <label>Epost</label>
                            <Input
                              disabled
                              defaultValue={customerProfile.email}
                              placeholder="Epost"
                              type="email"
                            />
                          </FormGroup>
                        </Col>
                        <Col className="pr-1" md="6">
                          <FormGroup>
                            <label>Telefon</label>
                            <Input
                              disabled
                              defaultValue={customerProfile.mobilePhone}
                              placeholder="Telefon"
                              type="number"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-1" md="6">
                          <FormGroup>
                            <label>Adresse</label>
                            <Input
                              disabled
                              defaultValue={customerProfile.InvoiceAdress}
                              placeholder="Adresse"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col className="pr-1" md="6">
                          <FormGroup>
                            <label>PostNr</label>
                            <Input defaultValue={customerProfile.invoicePostal}
                              disabled
                              placeholder="PostNr"
                              type="number" />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-1" md="6">
                          <FormGroup>
                            <label>Poststed</label>
                            <Input
                              disabled
                              defaultValue={customerProfile.invoiceCity}
                              placeholder="Poststed"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <label>Informasjon til elektrikeren:</label>
                            <Input
                              disabled
                              className="textarea"
                              type="textarea"
                              cols="80"
                              rows="4"
                              defaultValue="Veibeskrivelse eller annen informason som hjelper elektrikeren å finne frem."
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <br></br>
                      <b>Vennligst kontakt kundeservice dersom informasjonen ikke stemmer.</b>
                      <br></br><br></br>
                      <p>{sessionStorage.getItem("company")}</p>
                      <p>{sessionStorage.getItem("companyInfo")}</p>
                      <a href={sessionStorage.getItem("companyUrl")}>{sessionStorage.getItem("companyUrl")}</a>
                      {/* <Row>
                        <Col md="12">
                          <FormGroup>
                            <Input type="checkbox" style={{marginLeft: '0px'}} />{' '}
                            <Label style={{marginLeft: '20px'}}>Jeg ønsker å motta informasjon og gode tilbud på <b>sms</b></Label>  
                            <br></br>
                            <Input type="checkbox" style={{marginLeft: '0px'}} />{' '}
                            <Label style={{marginLeft: '20px'}}>Jeg ønsker å motta informasjon og gode tilbud på <b>epost</b></Label>  
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="text-left" md="6" xs="6">
                          <Button type="submit" color="success" onClick={this.onFormSubmit}>
                            Oppdater kundeinformasjon
                        </Button>
                        </Col>
                      </Row> */}
                    </Form>
                  </CardBody>
                </Card>

              </Col>
            </Row>
          </div>
        </>
      );
    }
  }
}

export default UserProfile;
